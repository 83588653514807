import React from "react";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";

interface ExpiredQuestionnaireModalProps {
    onClose?: () => void;
}

const ExpiredQuestionnaireModal: React.FunctionComponent<ExpiredQuestionnaireModalProps> = ({
    onClose,
}: ExpiredQuestionnaireModalProps) => {
    const { translations } = useLanguage();
    const t = translations.expiredSection;

    // Rendering

    return (
        <div>
            <p style={{ whiteSpace: "break-spaces" }}>{t.message}</p>
            <div className="dual-button-holder">
                <Button onClick={onClose}>{t.restart}</Button>
            </div>
        </div>
    );
};

export default ExpiredQuestionnaireModal;
