import { gray } from "@ant-design/colors";
import { DownSquareOutlined, UpSquareOutlined } from "@ant-design/icons";
import { Collapse, CollapseProps } from "antd";
import styled from "styled-components";
import styles from "./custom-collapse.module.css";

export const CustomCollapse = ({
    items,
}: {
    items: CollapseProps["items"];
}) => {
    const renderExpandIcon = ({
        isActive,
    }: {
        isActive?: boolean;
    }): React.ReactNode =>
        isActive ? (
            <DownSquareOutlined className={styles.collapseIcon} />
        ) : (
            <UpSquareOutlined className={styles.collapseIcon} />
        );

    return (
        <StyyledCollapse
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }: { isActive?: boolean }) =>
                renderExpandIcon({ isActive })
            }
            items={items}
        />
    );
};

const StyyledCollapse = styled(Collapse)`
    border-radius: 0px;
    box-shasow: 8px ${gray[6]};
    .ant-collapse-content {
        background-color: inherit;
        border-top: 0px;
    }

    .ant-collapse-expand-icon {
        margin: auto;
        padding: 4px;
    }

    .ant-collapse-expand-icon > span {
        margin-top: 0px !important;
    }

    // Override border-top
    .ant-collapse-content::before {
        content: "";
        display: block;
        width: 97%;
        height: 1px;
        background-color: ${gray[1]};
        margin: auto;
    }
`;
