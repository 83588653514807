import React, { useEffect } from "react";
import useLanguage from "../../hooks/language";
import {
    LOWERCASE_REGEX,
    UPPERCASE_REGEX,
    DIGIT_REGEX,
    SPECIAL_CHAR_REGEX,
} from "../../utils/validation";

import styles from "./password-validator.module.css";

interface PasswordValidatorProps {
    password: string;
    setValid?: (valid: boolean) => void;
}

interface Condition {
    message: string;
    met: boolean;
}

const MIN_LENGTH = 10;

const PasswordValidator: React.FunctionComponent<PasswordValidatorProps> = ({
    password,
    setValid,
}: PasswordValidatorProps) => {
    const { translations } = useLanguage();
    const t = translations.login.passwordErrors;

    const conditions: Condition[] = [
        {
            message: t.minLength,
            met: password.length >= MIN_LENGTH,
        },
        {
            message: t.needLowercase,
            met: LOWERCASE_REGEX.test(password),
        },
        {
            message: t.needUppercase,
            met: UPPERCASE_REGEX.test(password),
        },
        {
            message: t.needNumber,
            met: DIGIT_REGEX.test(password),
        },
        {
            message: t.needSpecialSymbol,
            met: SPECIAL_CHAR_REGEX.test(password),
        },
    ];

    // Effects

    useEffect(() => {
        if (!setValid) return;
        if (conditions.some(({ met }) => !met)) setValid(false);
        else setValid(true);
    }, [password]);

    return (
        <ul className={styles.list}>
            {conditions.map(({ message, met }, index) => (
                <li key={index} className={met ? styles.met : ""}>
                    {message}
                </li>
            ))}
        </ul>
    );
};

export default PasswordValidator;
