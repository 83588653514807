import { Checkbox, Form, Input, Select } from "antd";
import { Button, Modal } from "components";
import dayjs from "dayjs";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { canDeliverToPrelib, Delivery } from "models/delivery";
import { DeliveryDestinationOptions } from "models/delivery-settings";
import User, { upcomingScreeningAppointment } from "models/user";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledSelect } from "..";
import {
    useEditAddressModalVisible,
    useFutureDeliveries,
    useNextDelivery,
    useSelectedDelivery,
    useSetEditAddressModalVisible,
    useUpdateDelivery,
    useUpdateSettings,
} from "../../../utils/context";
import styles from "./modals.module.css";

const { Option } = Select;
const { Item } = Form;

export const EditAddressModal = (): JSX.Element => {
    // hooks
    const [authUser] = useAuthUser();
    const futureDeliveries = useFutureDeliveries();
    const nextDelivery = useNextDelivery();
    const delivery = useSelectedDelivery();
    const updateSettings = useUpdateSettings();
    const updateDelivery = useUpdateDelivery();
    const setEditAddressModalVisible = useSetEditAddressModalVisible();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const {
        translations: {
            prepSection: {
                prepDelivery: {
                    active: { editAddress: t },
                },
            },
        },
    } = useLanguage();
    // states
    const editAddressModalVisible = useEditAddressModalVisible();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [submittable, setSubmittable] = useState<boolean>(false);

    // Hooks
    // Validate fields
    useEffect(() => {
        form.validateFields()
            .then(() => {
                setSubmittable(true);
            })
            .catch((e) => {
                if (e.errorFields.length > 0) setSubmittable(false);
            });
    }, [
        form.getFieldsValue().address,
        form.getFieldsValue().city,
        form.getFieldsValue().zipCode,
        form.getFieldsValue().destination,
        t,
    ]);

    // Event handlers
    const onSubmit = async () => {
        setIsLoading(true);
        const payload = {
            destination: values.destination,
            address: values.address,
            city: values.city,
            zipCode: values.zipCode,
            date: isPrelibSelected
                ? _upcomingScreeningAppointment?.datetime
                : undefined,
        };
        if (delivery && (!values.applyToAll || isPrelibSelected)) {
            await updateDelivery(payload, delivery.id);
        } else if (values.applyToAll) {
            await updateSettings(payload);
        }
        setEditAddressModalVisible(false);
        setIsLoading(false);
    };

    const initialValues = {
        destination: delivery?.destination,
        address: delivery?.address,
        city: delivery?.city,
        zipCode: delivery?.zipCode,
    };

    // Derived states
    const _canDeliverToPrelib = canDeliverToPrelib(
        authUser as User,
        delivery as Delivery,
        [...futureDeliveries, ...(nextDelivery ? [nextDelivery] : [])]
    );
    const _upcomingScreeningAppointment = upcomingScreeningAppointment(
        authUser as User
    );
    const isPrelibSelected =
        values?.destination === DeliveryDestinationOptions.PRELIB_PICK_UP;
    const isPharmacySelected =
        values?.destination === DeliveryDestinationOptions.PHARMACY_PICK_UP;

    const showAddresSection = !(isPrelibSelected || isPharmacySelected);

    const canAddPrelibOption =
        delivery?.destination === DeliveryDestinationOptions.PRELIB_PICK_UP ||
        _canDeliverToPrelib;

    return (
        <Modal
            title={t.title}
            visible={editAddressModalVisible}
            onVisibilityChange={(visibility: boolean) => {
                setEditAddressModalVisible(visibility);
            }}
        >
            <Form form={form} layout="vertical" initialValues={initialValues}>
                <Item name="destination" initialValue={delivery?.destination}>
                    <StyledSelect placeholder="Select a delivery method">
                        <Option value={DeliveryDestinationOptions.RESIDENCE}>
                            {t.destinationSelect.atHome}
                        </Option>
                        <Option value={DeliveryDestinationOptions.POST_PICK_UP}>
                            {t.destinationSelect.postPickUp}
                        </Option>
                        <Option
                            value={DeliveryDestinationOptions.PHARMACY_PICK_UP}
                        >
                            {t.destinationSelect.pharmacyPickUp}
                        </Option>
                        {canAddPrelibOption && (
                            <Option
                                value={
                                    DeliveryDestinationOptions.PRELIB_PICK_UP
                                }
                            >
                                {t.destinationSelect.prelibPickUp}
                            </Option>
                        )}
                    </StyledSelect>
                </Item>

                {showAddresSection && (
                    <>
                        <StyledItem
                            name="address"
                            label={t.address}
                            rules={[
                                {
                                    required: true,
                                    message: t.validationMessages.address,
                                },
                            ]}
                        >
                            <Input placeholder="ex: 1050 rue Lorem Ipsum" />
                        </StyledItem>

                        <div className={styles.addressRow}>
                            <StyledItem
                                style={{ flex: 3 }}
                                name="city"
                                label={t.city}
                                rules={[
                                    {
                                        required: true,
                                        message: t.validationMessages.city,
                                    },
                                ]}
                            >
                                <Input placeholder="ex: Montréal" />
                            </StyledItem>

                            <StyledItem
                                style={{ flex: 2 }}
                                name="zipCode"
                                label={t.zipCode}
                                rules={[
                                    {
                                        required: true,
                                        message: t.validationMessages.zipCode,
                                    },
                                ]}
                            >
                                <Input placeholder="ex: H3B0G3" />
                            </StyledItem>

                            <StyledItem
                                style={{ flex: 1 }}
                                name="province"
                                label={t.province}
                            >
                                <Input placeholder="QC" disabled />
                            </StyledItem>
                        </div>
                    </>
                )}

                {isPharmacySelected && (
                    <p className={styles.content}>{t.pharmacyPickUp}</p>
                )}

                {isPrelibSelected ? (
                    <p className={styles.content}>
                        {t.prelibPickUp}
                        <i>{`${_upcomingScreeningAppointment?.clinic.address} ${t.on} `}</i>
                        <b>
                            {dayjs(
                                _upcomingScreeningAppointment?.datetime
                            ).format("DD/MM/YYYY")}
                        </b>
                        {t.atNextAppointment}
                    </p>
                ) : (
                    <Item name="applyToAll" valuePropName="checked">
                        <Checkbox className="checkbox-label">
                            {t.applyToAll}
                        </Checkbox>
                    </Item>
                )}
            </Form>
            <br />
            <br />
            <div className="dual-button-holder">
                <Button
                    type="secondary"
                    onClick={() => setEditAddressModalVisible(false)}
                >
                    {t.reject}
                </Button>
                <Button
                    loading={isLoading}
                    onClick={onSubmit}
                    disabled={!submittable}
                >
                    {t.continue}
                </Button>
            </div>
        </Modal>
    );
};

const StyledItem = styled(Item)`
    .ant-input {
        border-radius: 0px;
    }

    .ant-form-item-control-input-content {
        height: 2.8rem;
    }

    input {
        height: 100%;
    }
`;
