import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import { Button, Field } from "../../components";
import { EMAIL_REGEX } from "../../utils/validation";
import useLanguage from "../../hooks/language";
import ENDPOINTS from "../../utils/endpoints";
import { Footer, Header } from "../../components";

const PasswordForgottenPage: React.FunctionComponent = () => {
    const { translations } = useLanguage();
    const t = translations.forgottenPassword;

    // States

    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    // Errors

    const emailError = !EMAIL_REGEX.test(email) ? t.invalidEmailError : null;

    // Handlers

    const requestPasswordResetEmail = async () => {
        setSubmitted(true);

        if (emailError) return;

        setLoading(true);

        try {
            await Axios.put(ENDPOINTS.REQUEST_RESET_PASSWORD_EMAIL, { email });

            NotificationManager.success(t.successMessage);
        } finally {
            setLoading(false);
        }
    };

    // Rendering

    return (
        <div className="page">
            <Header />

            <div className="preauth-container">
                <h2>{t.header}</h2>
                <p>
                    {t.rhetoricalQuestion} {t.instruction}
                </p>
                <div className="input-holder">
                    <label>{t.emailLabel}</label>
                    <Field
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        onPressEnter={requestPasswordResetEmail}
                        error={submitted ? emailError : null}
                    />
                </div>
                <Button loading={loading} onClick={requestPasswordResetEmail}>
                    {t.sendButtonText}
                </Button>
            </div>

            <Footer />
        </div>
    );
};

export default PasswordForgottenPage;
