import { DateTime } from "luxon";
import { isValidRAMQ, PHONE_NUMBER_REGEX } from "utils/validation";
import Answer, { extractLocaleAnswer } from "./answer";

export type QuestionType =
    | "radio"
    | "checkbox"
    | "text"
    | "date"
    | "number"
    | "year";

export type QuestionSubtype = "email" | "ramq" | "phone" | "autocomplete";

export default interface Question {
    id: number;
    questionnaireId?: number;
    type: QuestionType;
    key: string;
    index: string;
    subType?: QuestionSubtype;
    optionalDisplayRef?: string;
    optionalDisplayAnswers?: string;
    isOptional?: boolean;
    frTranslation: string;
    enTranslation: string;
    maxlength?: number;
    answers: Answer[];
    answer: any;
    selectedAnswers?: Answer[];
}

export const extractLocaleQuestion = (
    { frTranslation, enTranslation }: Question,
    language: string
): string => {
    return language === "en" ? enTranslation : frTranslation;
};

export const canSubmitQuestion = ({
    isOptional,
    answer,
    type,
}: Question): boolean => {
    if (isOptional === true || type === "checkbox") {
        return true;
    }
    if (answer && answer.rawValue && typeof answer.rawValue === "string") {
        return answer.rawValue.length > 0;
    }
    return false;
};

export const extractQuestionError = (
    { isOptional, key, answer, type, subType, answers }: Question,
    translations: any
): string | undefined => {
    if (isOptional === true) {
        return;
    }

    const t = translations.questionnaireComponent;

    const noAnswer = !answer || !answer.rawValue;

    if (["radio", "date", "year"].indexOf(type) > -1 && noAnswer) {
        return t.selectRequiredFieldError;
    } else if (["number", "text"].indexOf(type) > -1) {
        if (
            subType === "ramq" &&
            answer.rawValue &&
            !isValidRAMQ(answer.rawValue)
        ) {
            return t.ramqNumberInvalidError;
        }
        if (subType === "phone" && !PHONE_NUMBER_REGEX.test(answer.rawValue)) {
            return t.phoneInvalidError;
        }
        if (
            subType === "autocomplete" &&
            (noAnswer ||
                answer.rawValue.length === 0 ||
                !answers
                    .map(({ rawValue }) => rawValue)
                    .includes(answer.rawValue))
        ) {
            return t.selectRequiredFieldError;
        }
        if ((noAnswer || answer.rawValue.length === 0) && subType !== "ramq") {
            return t.requiredFieldError;
        }

        // Very specific use case for people under 15
    } else if (key === "birthDate" && type === "date") {
        const date = DateTime.fromISO(answer.rawValue);
        const now = DateTime.now();
        const diff = now.diff(date, ["years"]);
        if (diff.years < 15) {
            return t.underFifteenError;
        }
    }

    return;
};

export interface UserQuestionnaireAnswer {
    optionalAnswerId: number;
    questionId: number;
    rawValue: string;
}

export const mapAnswerToUserQuestionnaireAnswer = (
    { id: optionalAnswerId, rawValue }: Answer | any,
    { id: questionId }: Question
): UserQuestionnaireAnswer => ({
    optionalAnswerId,
    questionId,
    rawValue,
});

export const extractAnswerFromQuestion = (
    question: Question
): UserQuestionnaireAnswer | UserQuestionnaireAnswer[] | undefined => {
    const { answer, selectedAnswers, type } = question;

    if (
        type === "checkbox" &&
        selectedAnswers &&
        Array.isArray(selectedAnswers)
    ) {
        return selectedAnswers.map((answer) =>
            mapAnswerToUserQuestionnaireAnswer(answer, question)
        );
    } else if (answer) {
        return mapAnswerToUserQuestionnaireAnswer(answer, question);
    }

    return undefined;
};

export const findAnswerFromValue = (
    { answers }: Question,
    value: string
): Answer | undefined => {
    return answers.find(({ rawValue }) => rawValue === value);
};

export const findAnswerFromLocaleAnswer = (
    { answers }: Question,
    localAnswer: string,
    language: string
): Answer | undefined => {
    return answers.find(
        (answer) => extractLocaleAnswer(answer, language) === localAnswer
    );
};

export const getYearsArray = (): string[] => {
    const now = new Date();
    const years: string[] = [];

    for (let i = 0; i < 20; i++) {
        now.setFullYear(now.getFullYear() - 1);
        years.push(`${now.getFullYear()}`);
    }

    return years;
};
