import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";

import styles from "./button-select.module.css";

interface ButtonSelectProps {
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    multiple?: boolean;
    options: string[];
    selection?: undefined | string | string[];
    onChange?: (selection: null | string | string[]) => void;
}

const ButtonSelect: React.FunctionComponent<ButtonSelectProps> = ({
    className,
    disabled = false,
    loading = false,
    multiple = false,
    options,
    selection,
    onChange,
}: ButtonSelectProps) => {
    // States

    const [_selection, setSelection] = useState<null | string | string[]>(
        selection ?? multiple ? [] : null
    );

    // Effects

    useEffect(() => {
        if (onChange && _selection !== selection) onChange(_selection);
    }, [_selection]);

    useEffect(() => {
        if (selection) setSelection(selection);
    }, [selection]);

    // Helpers

    const toggleOptionInSingleSelection = (option: string) => {
        if (Array.isArray(_selection)) return;

        if (_selection === option) {
            setSelection(null);
        } else {
            setSelection(option);
        }
    };

    const toggleOptionInMultipleSelection = (option: string) => {
        if (!Array.isArray(_selection)) return;

        const optionIndex = _selection.indexOf(option);

        if (optionIndex > -1) {
            const newSelection = [..._selection];
            newSelection.splice(optionIndex, 1);
            setSelection(newSelection);
        } else {
            setSelection([..._selection, option]);
        }
    };

    // Handlers

    const onClickOption = (option: string) => {
        if (multiple) {
            toggleOptionInMultipleSelection(option);
        } else {
            toggleOptionInSingleSelection(option);
        }
    };

    // Rendering

    const renderOption = (option: string) => {
        let selected = false;
        if (Array.isArray(_selection)) {
            selected = _selection.includes(option);
        } else if (typeof _selection === "string") {
            selected = option === _selection;
        }

        return (
            <button
                onClick={() => onClickOption(option)}
                className={selected ? styles.selected : ""}
                disabled={disabled}
            >
                {option}
            </button>
        );
    };

    let rootClass = styles.buttonSelector;
    if (className) rootClass += ` ${className}`;

    return (
        <div className={rootClass}>
            {loading && <Loader className="spinorama" />}
            {options.map(renderOption)}
        </div>
    );
};

export default ButtonSelect;
