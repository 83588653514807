import { gray } from "@ant-design/colors";
import { Select } from "antd";
import dayjs from "dayjs";

import useLanguage from "hooks/language";
import { DeliverySettings } from "models";
import { STARTING_PREP_DELIVERY_YEAR } from "models/delivery";
import { DeliveryFrequencyOptions } from "models/delivery-settings";
import { Loader } from "react-feather";
import styled from "styled-components";
import { EditAddressModal } from "../components/action-buttons-group/modals/edit-address";
import { DeliveryPreferencesForm } from "../components/delivery-preferences-form";
import { DeliverySettingsForm } from "../components/delivery-settings-form";
import {
    useDeliveryPreferencesModalVisible,
    useDeliverySettings,
    useEditAddressModalVisible,
    useFutureDeliveries,
    useIsLoading,
    useNextDelivery,
    useSetPastDeliveriesYears,
} from "../utils/context";
import { CurrentPlan } from "./current-plan";
import styles from "./delivery-tab.module.css";
import { FutureDeliveries } from "./future-deliveries";
import { NextDelivery } from "./next-delivery";
import { PastDeliveries } from "./past-deliveries";

const { Option } = Select;

/**
 * The contents of the PrEP delivery tab depends on whether the user is ready
 * to receive deliveries. In order to be ready, they need to have valid settings.
 */
export const PrEPDeliveryTab = (): JSX.Element => {
    const isLoading = useIsLoading();
    const settings = useDeliverySettings();

    if (isLoading) {
        return <Loader className={`spinorama ${styles.loader}`} />;
    } else if (
        settings &&
        settings.frequency !== DeliveryFrequencyOptions.ON_PAUSE
    ) {
        // The child component could use the `useDeliverySettings` hook itself,
        // but then it would get a `DeliverySettings | null`. Here I want to enforce
        // the contract that "ActiveSection only works with non-null settings".
        return <ActiveSection settings={settings} />;
    } else {
        return <InactiveSection />;
    }
};

/**
 * This section is displayed if the user has valid delivery settings.
 * Here they can see their delivery plan, their future and past deliveries,
 * and they can change their settings or request an edit on a planned delivery.
 */
const ActiveSection = ({ settings }: { settings: DeliverySettings }) => {
    const nextDelivery = useNextDelivery();
    const futureDeliveries = useFutureDeliveries();
    const editAddressModalVisible = useEditAddressModalVisible();
    const deliveryPreferencesModalVisible = useDeliveryPreferencesModalVisible();
    const setPastDeliveriesYears = useSetPastDeliveriesYears();
    const {
        translations: {
            prepSection: {
                prepDelivery: { active: t },
            },
        },
    } = useLanguage();

    const years = [];
    for (let i = dayjs().year(); i >= STARTING_PREP_DELIVERY_YEAR; i--) {
        years.push({ value: String(i), label: String(i) });
    }

    return (
        <section className={`container ${styles.columnLayout}`}>
            <CurrentPlan settings={settings} />
            {nextDelivery && (
                <div>
                    <h3>{t.nextDeliveryTitle}</h3>
                    <NextDelivery />
                </div>
            )}
            {futureDeliveries && futureDeliveries.length > 0 && (
                <div>
                    <h3>{t.futureDeliveriesTitle}</h3>
                    <FutureDeliveries />
                </div>
            )}
            {/* Past deliveries section */}
            <div>
                <div className={styles.pastDeliveriesHeaderContainer}>
                    <h3>{t.pastDeliveriesTitle}</h3>
                    <StyledSelect
                        defaultValue={years[0]["value"]}
                        style={{ width: 90 }}
                        onSelect={(
                            value: unknown //prevent weird ts warning
                        ) => setPastDeliveriesYears(value as string)}
                    >
                        {years.map((year) => (
                            <Option key={year.label} value={year.value}>
                                {year.value}
                            </Option>
                        ))}
                    </StyledSelect>
                </div>
                <PastDeliveries />
            </div>
            {editAddressModalVisible && <EditAddressModal />}
            {deliveryPreferencesModalVisible && <DeliveryPreferencesForm />}
        </section>
    );
};

/**
 * This section is displayed if the user has no delivery settings.
 * Here, a user wanting PrEP deliveries can complete and submit a delivery
 *  settings form, which the pharmacist will review on their platform.
 */
const InactiveSection = () => {
    const {
        translations: {
            prepSection: {
                prepDelivery: { inactive: t },
            },
        },
    } = useLanguage();

    return (
        <section className={`container ${styles.inactive}`}>
            <h2>{t.title}</h2>
            <hr />
            <p>{t.description}</p>
            <DeliverySettingsForm />
        </section>
    );
};

const StyledSelect = styled(Select)`
    .ant-select-selector {
        background: inherit !important;
        border: 2px solid ${gray[4]} !important;
        border-radius: 0px;
    }

    .ant-select-arrow {
        color: ${gray[4]} !important;
    }
    height: 2rem;
`;
