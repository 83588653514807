import { DEFAULT_LANGUAGE } from "./constants";
import { UserLanguage } from "./types";

const AUTH_TOKEN_KEY = "auth_token";

export const persistAuthToken = (token: string | null): void => {
    if (!token) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    } else {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
    }
};

export const getPersistedAuthToken = (): string | null => {
    return localStorage.getItem(AUTH_TOKEN_KEY);
};

const USER_LANGUAGE = "user_language";

export const persistUserLanguage = (language: UserLanguage): void => {
    if (!language) {
        localStorage.removeItem(USER_LANGUAGE);
    } else {
        localStorage.setItem(USER_LANGUAGE, language);
    }
};

export const getPersistedUserLanguage = (): UserLanguage => {
    return (
        (localStorage.getItem(USER_LANGUAGE) as UserLanguage) ??
        DEFAULT_LANGUAGE
    );
};

export const setCookie = (name: string, value: string, days: number): void => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name: string): string | null => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};
