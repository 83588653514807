import useAuthToken from "hooks/auth-token";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Footer, Header } from "../../components";
import ROUTES from "../../utils/routes";
import LoginForm from "./login-form";
import styles from "./login.module.css";

const LoginPage: React.FunctionComponent = () => {
    const { push } = useHistory();
    const [authToken] = useAuthToken();

    // States

    useEffect(() => {
        if (authToken) {
            push(ROUTES.HOME);
        }
    }, []);

    // Rendering

    return (
        <div className="page">
            <Header />

            <div className={`${styles.container} preauth-container`}>
                <LoginForm />
            </div>

            <Footer />
        </div>
    );
};

export default LoginPage;
