import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import { Footer, Header, Button, Field, Checkbox } from "../../components";
import useLanguage from "../../hooks/language";
import ENDPOINTS from "../../utils/endpoints";
import useAuthToken from "../../hooks/auth-token";
import ROUTES from "../../utils/routes";
import { setCookie } from "../../utils/local-storage";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

const REMEMBER_ME_TOKEN_DURATION = 30;
const REMEMBER_ME_TOKEN_NAME = "rememberMeToken";

const TfaInputPage: React.FunctionComponent = () => {
    const { push } = useHistory();
    const setAuthToken = useAuthToken()[1];
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const { translations } = useLanguage();
    const t = translations.tfaInput;
    const [analytics] = useAnalytics();

    // States

    const [submitted, setSubmitted] = useState(false);
    const [code, setCode] = useState("");
    const [rememberBrowser, setRememberBrowser] = useState(false);
    const [loading, setLoading] = useState(false);

    // Validations

    const tfaError = code.length < 6 ? t.codeError : null;
    const disabled = code.length === 0 || (submitted && tfaError !== null);

    // Network

    const verify = async () => {
        setSubmitted(true);

        if (tfaError) return;

        setLoading(true);

        const tfaToken = searchParams.get("tfaToken") || undefined;

        try {
            const { data } = await Axios.post(ENDPOINTS.VERIFY_TFA, {
                code,
                tfaToken,
                rememberBrowser,
            });

            const eventProperties = {
                type: "tfa",
                method: sentBy,
                stopAsking: rememberBrowser,
            };
            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.LoginCompleted, eventProperties);
            analytics
                ?.web()
                ?.trackEvent(UserActionEvent.LoginCompleted, eventProperties);
            const { authToken, rememberMeToken } = data;
            if (rememberMeToken) {
                setCookie(
                    REMEMBER_ME_TOKEN_NAME,
                    rememberMeToken,
                    REMEMBER_ME_TOKEN_DURATION
                );
            }
            setAuthToken(authToken);
            push(ROUTES.HOME);
        } finally {
            setLoading(false);
        }
    };

    // const requestCode = async () => {
    //   const tfaToken = searchParams.get("tfaToken") || undefined;
    //   await Axios.post(ENDPOINTS.REQUEST_TFA_CODE, {
    //     tfaToken,
    //   });
    // };

    // Rendering

    const sentBy = searchParams.get("sentBy") || undefined;
    const sentTo = searchParams.get("sentTo") || undefined;

    // TODO: resend tfa

    return (
        <div className="page">
            <Header />

            <div className="preauth-container">
                <h2>{t.title}</h2>
                <p>{t.sentTo(sentBy, sentTo)}</p>
                <div className="input-holder">
                    <label>{t.fieldLabel}</label>
                    <Field
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        onPressEnter={verify}
                        error={submitted ? tfaError : null}
                    />
                    <br />
                    <Checkbox
                        checked={rememberBrowser}
                        onChange={setRememberBrowser}
                    >
                        {t.checkboxLabel}
                    </Checkbox>
                </div>
                <a>{t.resendCode}</a>
                <Button disabled={disabled} loading={loading} onClick={verify}>
                    {t.verifyButton}
                </Button>
            </div>

            <Footer />
        </div>
    );
};

export default TfaInputPage;
