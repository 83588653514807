import { DateTime } from "luxon";
import {
    hasFollowUpPhoneAppointmentToComplete,
    upcomingScreeningAppointment,
} from "models/user";
import useLanguage from "hooks/language";
import useAuthUser from "hooks/auth-user";

export const useUpcomingAppointmentInfo = () => {
    const [authUser] = useAuthUser();
    const { language } = useLanguage();

    const upcomingAppointment = authUser
        ? upcomingScreeningAppointment(authUser)
        : null;

    return {
        date: upcomingAppointment
            ? DateTime.fromISO(upcomingAppointment.datetime)
                  .setLocale(language)
                  .toLocaleString(DateTime.DATE_MED)
            : "",
        isLastAppointmentScheduled: upcomingAppointment !== undefined,
        hasFollowUp:
            authUser && hasFollowUpPhoneAppointmentToComplete(authUser),
    };
};
