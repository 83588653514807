import React from "react";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";

interface ConcentModalProps {
    onClose?: () => void;
    onConsent?: () => void;
}

const ConcentModal: React.FunctionComponent<ConcentModalProps> = ({
    onClose,
    onConsent,
}: ConcentModalProps) => {
    const { translations } = useLanguage();
    const t = translations.questionnaireSection.modal;

    // Rendering

    return (
        <div>
            <p>{t.consentFirstP}</p>
            <p>{t.consentSecondP}</p>
            <p>{t.consentThirdP}</p>
            <p>{t.consentFourthP}</p>
            <div className="dual-button-holder">
                <Button onClick={onClose} type="secondary">
                    {t.refuseButtonText}
                </Button>
                <Button onClick={onConsent}>{t.consentButtonText}</Button>
            </div>
        </div>
    );
};

export default ConcentModal;
