import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useLanguage from "../../hooks/language";
import useAuthUser from "../../hooks/auth-user";
import { Footer, Modal, Header } from "../../components";
import { ConfirmPhoneNumberModal } from "../../modals";
import ROUTES from "../../utils/routes";

import styles from "./home.module.css";

import ScreeningPage from "./screening";
import MyAppointments from "./my-appointments";
import { MyPrEP } from "./my-prep";
import { useLastAppointment } from "context/last-appointment";
import MissedAppointmentPaymentModal from "../../modals/missed-appointment-payment";

import { Nav } from "./nav";

const HomePage: React.FunctionComponent = () => {
    const [authUser, setAuthUser] = useAuthUser();
    const {
        hasToPayLastMissedAppointment,
        setShowPaymentForm,
    } = useLastAppointment();
    const { translations } = useLanguage();
    const tHome = translations.homePage;

    // Effects
    useEffect(() => {
        if (!authUser) return;

        // If user tfa pref is sms and phone number is not confirmed, user needs to be prompted
        if (
            authUser.tfaPreference === "sms" &&
            !authUser.phoneNumberConfirmed
        ) {
            setConfirmationModalVisible(true);
        }
    }, [authUser]);

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(
        false
    );

    // Handlers

    const onConfirmPhoneNumber = () => {
        setConfirmationModalVisible(false);
        if (authUser) setAuthUser({ ...authUser, phoneNumberConfirmed: true });
    };

    const onScreeningLinkClick = (e: React.MouseEvent) => {
        if (hasToPayLastMissedAppointment) {
            e.preventDefault();
            setShowPaymentForm(true);
        }
    };

    return (
        <div>
            {/* Phone confirmation modal */}
            <Modal
                title={translations.phoneConfirmationModal.title}
                visible={confirmationModalVisible}
                onVisibilityChange={setConfirmationModalVisible}
            >
                <ConfirmPhoneNumberModal
                    visible={confirmationModalVisible}
                    phoneNumber={authUser ? authUser.phone : ""}
                    onConfirm={onConfirmPhoneNumber}
                    linkToProfile={true}
                />
            </Modal>
            <Header />
            <div className={styles.header}>
                <div className={styles.myFile}>
                    <p>{tHome.logo.myDirectoryDecorator}</p>
                </div>
                <Nav onScreeningLinkClick={onScreeningLinkClick} />
            </div>
            <MissedAppointmentPaymentModal />
            <Switch>
                <Route exact path={`${ROUTES.APPOINTMENTS}`}>
                    <MyAppointments />
                </Route>
                <Route path={`${ROUTES.SCREENING}`}>
                    <ScreeningPage />
                </Route>
                <Route path={ROUTES.PREP}>
                    <MyPrEP />
                </Route>
                <Redirect to={`${ROUTES.APPOINTMENTS}`} />
            </Switch>

            <Footer />
        </div>
    );
};

export default HomePage;
