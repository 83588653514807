import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, CollapseProps } from "antd";
import useLanguage from "hooks/language";
import { useState } from "react";
import { useFutureDeliveries } from "../../utils/context";
import { collapseItem } from "../components/collapse-item";
import { CustomCollapse } from "../components/custom-collapse";
import { TabHeader } from "../components/tab-header";
import styles from "./future-deliveries.module.css";

export const FutureDeliveries = (): JSX.Element | null => {
    // States
    const [itemCount, setItemCount] = useState(3);

    // Hooks
    const futureDeliveries = useFutureDeliveries();
    const {
        translations: {
            prepSection: {
                prepDelivery: { active: t },
            },
        },
    } = useLanguage();
    if (!futureDeliveries) return null;

    // Handlers
    const handleLoadMore = () => {
        if (isFullyLoaded) setItemCount(3);
        else
            setItemCount((prevItemCount) =>
                Math.min(prevItemCount + 3, futureDeliveries.length)
            );
    };

    // Derived states
    const isFullyLoaded = itemCount >= futureDeliveries.length;
    const items: CollapseProps["items"] = futureDeliveries
        .slice(0, itemCount)
        .map((delivery, index) => collapseItem(delivery, String(index + 1)));

    return (
        <>
            {futureDeliveries && futureDeliveries.length > 0 && <TabHeader />}
            <CustomCollapse items={items} />
            <div className={styles.flexEnd}>
                <Button
                    type="text"
                    className={styles.showMore}
                    onClick={handleLoadMore}
                >
                    {isFullyLoaded ? t.showLess : t.showMore}
                    {isFullyLoaded ? <UpOutlined /> : <DownOutlined />}
                </Button>
            </div>
        </>
    );
};
