import { Gear } from "phosphor-react";
import { FunctionComponent } from "react";

import { Button } from "../../../../../components";
import { OnDemandForm } from "./on-demand-form";

import useLanguage from "hooks/language";
import { hasUpcomingIncompleteDelivery } from "models/delivery";
import {
    DeliveryFrequencyOptions,
    DeliverySettings,
} from "models/delivery-settings";
import {
    useFutureDeliveries,
    useNextDelivery,
    useSetDeliveryPreferencesModalVisible,
} from "../../utils/context";

import styles from "./current-plan.module.css";

type CurrentPlan = {
    settings: DeliverySettings;
};

export const CurrentPlan: FunctionComponent<CurrentPlan> = ({
    settings,
}: CurrentPlan): JSX.Element => {
    // Hooks
    const nextDelivery = useNextDelivery();
    const futureDeliveries = useFutureDeliveries();
    const setDeliveryPreferencesModalVisible = useSetDeliveryPreferencesModalVisible();
    const {
        translations: {
            prepSection: {
                prepDelivery: { active: t },
            },
        },
    } = useLanguage();

    // Components
    const title = <h2>{t.title[settings.frequency]}</h2>;
    const description =
        settings.frequency === DeliveryFrequencyOptions.ON_PAUSE ? null : (
            <p>{t.description[settings.frequency]}</p>
        );

    const shouldRenderOnDemandForm = () => {
        if (settings.frequency !== DeliveryFrequencyOptions.ON_DEMAND) {
            return false;
        }

        const combinedDeliveries = nextDelivery
            ? [...(futureDeliveries || []), nextDelivery]
            : undefined;

        return !hasUpcomingIncompleteDelivery(combinedDeliveries);
    };

    return (
        <div>
            <div className={styles.titleContainer}>
                {title}
                <Button
                    type="secondary"
                    className={styles.headerButton}
                    onClick={() => setDeliveryPreferencesModalVisible(true)}
                >
                    <div>
                        <Gear size={16} weight="bold" />
                        <span>{t.preferencesCTA}</span>
                    </div>
                </Button>
            </div>
            <hr />
            <div className={styles.contentSection}>
                {description}
                {shouldRenderOnDemandForm() && <OnDemandForm />}
            </div>
        </div>
    );
};
