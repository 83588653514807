import React, { useEffect } from "react";
import { ThumbsDown, ThumbsUp } from "react-feather";
import { Button } from "../../index";
import useLanguage from "../../../hooks/language";
import styles from "./boolean-input.module.css";

interface BooleanInputProps {
    onChange?: (value: boolean) => void;
    selectedValue?: boolean;
}
const BooleanInput: React.FunctionComponent<BooleanInputProps> = ({
    selectedValue,
    onChange,
}: BooleanInputProps) => {
    const { translations } = useLanguage();
    const [_selectedValue, setSelectedValue] = React.useState<boolean | null>(
        selectedValue ?? null
    );

    useEffect(() => {
        if (selectedValue) setSelectedValue(selectedValue);
    }, [selectedValue]);

    const onSelectValue = (value: boolean) => {
        setSelectedValue(value);
        if (onChange) onChange(value);
    };

    return (
        <div className={`d-flex ${styles.inputHolder}`}>
            <Button
                type={
                    typeof _selectedValue === "boolean" && _selectedValue
                        ? "primary"
                        : "secondary"
                }
                onClick={() => onSelectValue(true)}
            >
                <div className="d-flex">
                    <ThumbsUp />
                    <span>
                        {translations.appointmentsSection.feedbackModal.yes}
                    </span>
                </div>
            </Button>
            <Button
                type={
                    typeof _selectedValue === "boolean" && !_selectedValue
                        ? "primary"
                        : "secondary"
                }
                onClick={() => onSelectValue(false)}
            >
                <div className="d-flex">
                    <ThumbsDown />
                    <span>
                        {translations.appointmentsSection.feedbackModal.no}
                    </span>
                </div>
            </Button>
        </div>
    );
};
export default BooleanInput;
