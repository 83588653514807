import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Button, Field } from "../../../components";
import useLanguage from "../../../hooks/language";
import ENDPOINTS from "../../../utils/endpoints";
import Appointment from "../../../models/appointment";

const elementOptions = {
    style: {
        base: {
            fontFamily: '"Poppins" sans-serif',
            fontSize: "16px",
            border: "1px solid #1C1C1C",
            fontWeight: "400",
            color: "#1C1C1C",
        },
    },
};

interface PaymentFormProps {
    appointment?: Appointment;
    datetime: string | null;
    clinicId: number | null;
    onCompletePayment?: () => void;
    onClose?: () => void;
}

const PaymentForm: React.FunctionComponent<PaymentFormProps> = ({
    appointment,
    datetime,
    clinicId,
    onCompletePayment,
    onClose,
}: PaymentFormProps) => {
    const { translations } = useLanguage();
    const stripe = useStripe();
    const elements = useElements();
    const t = translations.paymentSection;

    // States

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [cardHolderName, setCardHolderName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");

    // Validation

    const enabled =
        cardHolderName.length > 0 &&
        address.length > 0 &&
        city.length > 0 &&
        postalCode.length > 0;

    const payFees = async (token: any) => {
        if (!appointment || !datetime) return;

        const body = {
            token: token.id,
            datetime: datetime,
            clinicId: clinicId,
        };

        try {
            await Axios.put(
                ENDPOINTS.RESCHEDULE_APPPOINTEMENT_FEES(appointment.id),
                body
            );
            if (onCompletePayment) await onCompletePayment();
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handlers

    const onSubmit = async () => {
        if (!stripe || !elements) return;

        setIsSubmitting(true);

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) return;

        const { token, error } = await stripe.createToken(cardNumberElement, {
            name: cardHolderName,
            address_zip: postalCode,
            address_line1: address,
            address_city: city,
        });

        if (error) {
            NotificationManager.error(error.message);
            console.error(error);
            setIsSubmitting(false);
            return;
        }

        if (token) {
            await payFees(token);
            setIsSubmitting(false);
        }
    };

    // Rendering

    return (
        <div>
            <p>
                {translations.appointmentsSection.gatherCreditInfoModal.content}
                <a
                    href={
                        translations.appointmentsSection.creditInfoModal.faqUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {
                        translations.appointmentsSection.gatherCreditInfoModal
                            .link
                    }
                </a>
            </p>

            <div className="row">
                <div className="input-holder hundred">
                    <label>{t.cardHolderName}</label>
                    <Field
                        value={cardHolderName}
                        onChange={(e) => setCardHolderName(e.target.value)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="input-holder hundred">
                    <label>{t.cardNumber}</label>
                    <div className="stripe-element-wrapper">
                        <CardNumberElement options={elementOptions} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="input-holder fifty">
                    <label>{t.cardExpirationDate}</label>
                    <div className="stripe-element-wrapper">
                        <CardExpiryElement options={elementOptions} />
                    </div>
                </div>
                <div className="input-holder fifty">
                    <label>{t.cardSecurityCode}</label>
                    <div className="stripe-element-wrapper">
                        <CardCvcElement options={elementOptions} />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="input-holder thirty">
                    <label>{t.cardHolderAddress}</label>
                    <Field
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className="input-holder thirty">
                    <label>{t.cardHolderCity}</label>
                    <Field
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className="input-holder thirty">
                    <label>{t.cardHolderZipCode}</label>
                    <Field
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                    />
                </div>
            </div>

            <br />

            <p className="disclaimer">{t.privacyMessage}</p>
            <p className="disclaimer">
                <b>{t.delayDisclaimer}</b>
            </p>

            <div className="dual-button-holder">
                <Button
                    type="secondary"
                    disabled={isSubmitting}
                    onClick={onClose}
                >
                    {t.cancelButton}
                </Button>
                <Button
                    loading={isSubmitting}
                    onClick={onSubmit}
                    disabled={!stripe || !enabled}
                >
                    {t.payButtonText}
                </Button>
            </div>
        </div>
    );
};

export default PaymentForm;
