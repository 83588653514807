import React from "react";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";

interface EditAppointmentModalProps {
    onCancelClick?: () => void;
    onRescheduleClick?: () => void;
    canRescheduleFreely?: boolean;
}

const EditAppointmentModal: React.FunctionComponent<EditAppointmentModalProps> = ({
    onCancelClick,
    onRescheduleClick,
    canRescheduleFreely,
}: EditAppointmentModalProps) => {
    const { translations } = useLanguage();
    const t = translations.appointmentsSection.cancellationModal;

    // Rendering

    return (
        <div>
            {!canRescheduleFreely && (
                <p className="has-error">{t.cancelAppointmentNotAllowed}</p>
            )}
            <Button
                onClick={onCancelClick}
                type="secondary"
                disabled={!canRescheduleFreely ?? false}
            >
                {t.cancellationButtonText}
            </Button>
            <Button onClick={onRescheduleClick} type="secondary">
                {t.rescheduleButtonText}
            </Button>
            <a rel="noreferrer" target="_blank">
                {t.feesMightApply}
            </a>
        </div>
    );
};

export default EditAppointmentModal;
