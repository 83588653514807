import { useContext } from "react";
import AppContext from "../context/app";
import { UserLanguage } from "../utils/types";

type LanguageHook = {
    language: UserLanguage;
    setLanguage: (language: UserLanguage) => void;
    translations: any; // Any is not so sexy but it's kind of legacy stuff from olf code
};

const useLanguage = (): LanguageHook => {
    const { language, setLanguage, translations } = useContext(AppContext);
    return { language, setLanguage, translations };
};

export default useLanguage;
