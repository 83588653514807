import { DateTime } from "luxon";
import useLanguage from "../hooks/language";
export default interface Clinic {
    id: number;
    code: number;
    timezone: string;
    name: string;
    city: string;
    address: string;
    nextAvailableTime: string;
}

export const formatNextAvailableDate = (
    { nextAvailableTime, timezone }: Clinic,
    locale: string
): string | undefined => {
    if (!nextAvailableTime) return undefined;

    const date = DateTime.fromISO(nextAvailableTime, {
        zone: timezone,
    });

    const { translations, language } = useLanguage();
    const t = translations.humanizeDateFormatter;

    const today = DateTime.local();
    const tomorrow = DateTime.local().plus({ days: 1 });

    const hour = date
        .setLocale(language)
        .toLocaleString(DateTime.TIME_24_SIMPLE);

    const isToday = date.hasSame(today, "day");
    const isTomorrow = date.hasSame(tomorrow, "day");

    if (isToday || isTomorrow) {
        const relativeDayString = date.toRelativeCalendar({
            unit: "days",
            locale: locale,
        });
        return `${t.date(relativeDayString, "", hour)}`;
    } else {
        const day = date.day;
        const shortMonth = date.setLocale(language).toFormat("MMMM");
        const hour = date
            .setLocale(language)
            .toLocaleString(DateTime.TIME_24_SIMPLE);
        return `${t.date(day, shortMonth, hour)}`;
    }
};
