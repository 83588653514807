import React, { MouseEvent } from "react";
import { X } from "react-feather";
import styles from "./multiple-selection-field.module.css";

interface MultipleSelectionCellInterface {
    value: string;
    onClickRemove: () => void;
}

const MultipleSelectionCell: React.FunctionComponent<MultipleSelectionCellInterface> = ({
    value,
    onClickRemove,
}: MultipleSelectionCellInterface) => {
    // Handlers

    const onClick = (event: MouseEvent<SVGElement>) => {
        event.stopPropagation();
        onClickRemove();
    };

    // Rendering

    return (
        <div className={styles.cell}>
            {" "}
            {value} <X onClick={onClick} />{" "}
        </div>
    );
};

export default MultipleSelectionCell;
