import React from "react";
import styles from "./card.module.css";

type Props = { children: React.ReactNode; className?: string };

export const Card = ({ children, className }: Props): JSX.Element => {
    // Extract children for Header, Body, and Footer
    const header = React.Children.toArray(children).find(
        (child) => React.isValidElement(child) && child.type === Card.Header
    );

    const body = React.Children.toArray(children).find(
        (child) => React.isValidElement(child) && child.type === Card.Body
    );

    const footer = React.Children.toArray(children).find(
        (child) => React.isValidElement(child) && child.type === Card.Footer
    );

    return (
        <div className={`${styles.card} ${className ?? ""}`}>
            {header && <div className={styles.header}>{header}</div>}
            <div className={styles.content}>
                {body && <div className={styles.body}>{body}</div>}
                {footer && (
                    <>
                        <hr />
                        <>{footer}</>
                    </>
                )}
            </div>
        </div>
    );
};

Card.Header = function Header({ children }: Props) {
    return <>{children}</>;
};
Card.Body = function Body({ children }: Props) {
    return <>{children}</>;
};
Card.Footer = function Footer({ children }: Props) {
    return <>{children}</>;
};
