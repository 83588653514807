import React, { useState, ChangeEvent } from "react";
import { Search } from "react-feather";
import {
    AddressForm,
    Button,
    ButtonSelect,
    Checkbox,
    DateSelect,
    DateTimePicker,
    Field,
    Option,
    Select,
} from "../../components";
import { Selection } from "../../components/select";

const MOCK = [
    "bisou",
    "hibou",
    "minou",
    "voyou",
    "pitou",
    "merlin",
    "coquin",
    "vilain",
    "rocket",
    "titi",
    "linguini",
    "tortelini",
];

const SingleSearchSelectImplementation: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<string[] | null>(null);

    // Network

    const fetchOptions = (searchQuery: string) => {
        // API call simulation
        setIsLoading(true);
        setTimeout(() => {
            const filteredOptions = MOCK.filter((m) =>
                m.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
            );
            setOptions(filteredOptions);
            setIsLoading(false);
        }, 500);
    };

    // Handlers

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length < 3) {
            setOptions(null);
            return;
        }

        fetchOptions(event.target.value);
    };

    const onSelect = (
        selection: Selection | undefined | null | Selection[]
    ) => {
        if (!selection) return;
        if (Array.isArray(selection)) return;

        const { value } = selection;
        console.log(value);
    };

    // Rendering

    const renderOption = (option: string, index: number) => {
        return (
            <Option key={index} value={option}>
                {option}
            </Option>
        );
    };

    const renderOptions = () => {
        if (!options) return;
        return options.map(renderOption);
    };

    return (
        <Select
            placeholder="placeholder"
            onChange={onChange}
            onSelect={onSelect}
            search={true}
            loading={isLoading}
            keepTyping={!options}
        >
            {renderOptions()}
        </Select>
    );
};

const MultipleSearchSelectImplementation: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<string[] | null>(null);

    // Network

    const fetchOptions = (searchQuery: string) => {
        // API call simulation
        setIsLoading(true);
        setTimeout(() => {
            const filteredOptions = MOCK.filter((m) =>
                m.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
            );
            setOptions(filteredOptions);
            setIsLoading(false);
        }, 500);
    };

    // Handlers

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length < 3) {
            setOptions(null);
            return;
        }

        fetchOptions(event.target.value);
    };

    const onSelect = (
        selection: Selection | undefined | null | Selection[]
    ) => {
        if (!selection) return;
        if (!Array.isArray(selection)) return;
    };

    // Rendering

    const renderOption = (option: string, index: number) => {
        return (
            <Option key={index} value={option}>
                {option}
            </Option>
        );
    };

    const renderOptions = () => {
        if (!options) return;
        return options.map(renderOption);
    };

    return (
        <Select
            multiple
            placeholder="placeholder"
            onChange={onChange}
            onSelect={onSelect}
            search={true}
            loading={isLoading}
            keepTyping={!options}
        >
            {renderOptions()}
        </Select>
    );
};

const WigetsPage: React.FunctionComponent = () => {
    return (
        <div className="container">
            {/* Headers */}

            <h1>This is a title</h1>
            <h2>This is a subtitle</h2>
            {/* Address form */}
            <p>Address form</p>
            <div className="input-holder">
                <AddressForm />
            </div>

            {/* Fields */}
            <p>Fields</p>
            <div className="input-holder">
                <label>Enabled</label>
                <Field placeholder="placeholder" />
            </div>
            <div className="input-holder">
                <label>Disabled</label>
                <Field placeholder="placeholder" disabled />
            </div>
            <div className="input-holder">
                <label>Has error</label>
                <Field placeholder="placeholder" error="Wouspie daisy!!!" />
            </div>
            <div className="input-holder">
                <label>With icon</label>
                <Field placeholder="placeholder" icon={<Search />} />
            </div>
            <div className="input-holder">
                <label>Loading</label>
                <Field placeholder="placeholder" loading />
            </div>
            <div className="input-holder">
                <label>Textarea</label>
                <Field placeholder="placeholder" textArea />
            </div>
            <br />

            {/* Selects */}
            <p>Selects</p>
            <div className="input-holder">
                <label>Enabled</label>
                <Select placeholder="placeholder">
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Disabled</label>
                <Select placeholder="placeholder" disabled>
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Has error</label>
                <Select placeholder="placeholder" error="Wouspie daisy!!!">
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Loading</label>
                <Select placeholder="placeholder" loading>
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Search</label>
                <SingleSearchSelectImplementation />
            </div>
            <br />

            {/* Multiple selects */}
            <p>Mutliple selects</p>
            <div className="input-holder">
                <label>Enabled</label>
                <Select placeholder="placeholder" multiple>
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Disabled</label>
                <Select placeholder="placeholder" multiple disabled>
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Has error</label>
                <Select
                    placeholder="placeholder"
                    multiple
                    error="Wouspie daisy!!!"
                >
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Loading</label>
                <Select placeholder="placeholder" multiple loading>
                    <Option key={1} value="First">
                        <b>First option</b>&nbsp;•&nbsp;this is the first option
                    </Option>
                    <Option key={2} value="Second">
                        <b>Second option</b>&nbsp;•&nbsp;this is the second
                        option
                    </Option>
                    <Option key={3} value="Third">
                        <b>Third option</b>&nbsp;•&nbsp;this is the third option
                    </Option>
                </Select>
            </div>
            <div className="input-holder">
                <label>Search</label>
                <MultipleSearchSelectImplementation />
            </div>
            <br />

            {/* Button selects */}
            <p>Button selects</p>
            <div className="input-holder">
                <label>Enabled</label>
                <ButtonSelect options={MOCK} />
            </div>
            <div className="input-holder">
                <label>Disabled</label>
                <ButtonSelect disabled options={MOCK} />
            </div>
            <div className="input-holder">
                <label>Loading</label>
                <ButtonSelect loading options={[]} />
            </div>

            {/* Multiple button selects */}
            <p>Multiple button selects</p>
            <div className="input-holder">
                <label>Enabled</label>
                <ButtonSelect multiple options={MOCK} />
            </div>
            <div className="input-holder">
                <label>Disabled</label>
                <ButtonSelect multiple disabled options={MOCK} />
            </div>
            <div className="input-holder">
                <label>Loading</label>
                <ButtonSelect multiple loading options={[]} />
            </div>

            {/* Date select */}
            <p>Date select</p>
            <div>
                <div className="input-holder">
                    <label>Enabled</label>
                    <DateSelect />
                </div>
                <div className="input-holder">
                    <label>Disabled</label>
                    <DateSelect disabled />
                </div>
            </div>
            <br />

            {/* Buttons */}

            <p>Primary buttons</p>
            <div>
                <div>
                    <label>Enabled</label>
                    <Button>Button</Button>
                </div>
                <div>
                    <label>Disabled</label>
                    <Button disabled>Button</Button>
                </div>
                <div>
                    <label>Loading</label>
                    <Button loading>Button</Button>
                </div>
            </div>
            <br />

            <p>Secondary buttons</p>
            <div>
                <div>
                    <label>Enabled</label>
                    <Button type="secondary">Button</Button>
                </div>
                <div>
                    <label>Disabled</label>
                    <Button type="secondary" disabled>
                        Button
                    </Button>
                </div>
                <div>
                    <label>Loading</label>
                    <Button type="secondary" loading>
                        Button
                    </Button>
                </div>
            </div>
            <br />

            {/* Checkboxes */}

            <p>Checkboxes</p>
            <div>
                <div>
                    <label>Enabled</label>
                    <Checkbox>I agree</Checkbox>
                </div>
                <div>
                    <label>Disabled</label>
                    <Checkbox disabled>I agree</Checkbox>
                </div>
            </div>
            <br />
            <div>
                <DateTimePicker />
            </div>
        </div>
    );
};

export default WigetsPage;
