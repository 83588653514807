import Axios from "axios";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import signupIconSrc from "../../assets/images/signup-icon.svg";
import { Button, Footer, Header } from "../../components";
import ENDPOINTS from "../../utils/endpoints";
import styles from "./sign-up.module.css";
import ROUTES from "../../utils/routes";
import { useHistory } from "react-router-dom";

interface ResendConfirmationProps {
    email: string;
    localizationDictionary: { [key: string]: string };
}

const ResendConfirmation: React.FunctionComponent<ResendConfirmationProps> = (
    props: ResendConfirmationProps
) => {
    const { email, localizationDictionary } = props;
    const { push } = useHistory();
    const [loading, setLoading] = useState(false);

    const resendConfirmation = async () => {
        setLoading(true);

        try {
            await Axios.put(ENDPOINTS.RESEND_CONFIRMATION, { email });
            NotificationManager.success(
                `${localizationDictionary.confirmationResent}${email}`
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page">
            <Header />
            <div className={`${styles.container} preauth-container`}>
                <div>
                    <div className={styles.imgHolder}>
                        <img src={signupIconSrc} />
                    </div>

                    <div>
                        <h2 className={`${styles["single-line"]}`}>
                            {localizationDictionary.accountCreated}
                        </h2>
                        <div
                            className={`${styles.subtitle} ${styles["single-line"]}`}
                        >
                            <span>{localizationDictionary.clickHere}</span>
                            <b>
                                <a
                                    className={`${styles.subtitle}`}
                                    onClick={() => push(ROUTES.LOGIN)}
                                >
                                    {localizationDictionary.loginToAccount}
                                </a>
                            </b>
                        </div>
                    </div>

                    <p>
                        {localizationDictionary.emailConfirmationMessage}.{" "}
                        {localizationDictionary.confirmationResent}
                        <b>{email}</b>.
                    </p>

                    <Button
                        loading={loading}
                        type="secondary"
                        onClick={resendConfirmation}
                    >
                        {localizationDictionary.resend}
                    </Button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ResendConfirmation;
