import React from "react";
import Appointment from "../../models/appointment";
import PhoneAppointment from "../../models/phone-appointment";
import { isAppointment } from "../../utils/appointment-helpers";

import ScreeningAppointmentCard from "./screening-appointment-card";
import PhoneAppointmentCard from "./phone-appointment-card";

interface AppointmentCardProps {
    appointment: Appointment | PhoneAppointment;
    onEditClick?: () => void;
    onRescheduleClick?: () => void;
    onRetakeQuestionnaireClick?: () => void;
    onSchedulePhoneAppointmentClick?: () => void;
    onScheduleRetakeClick?: () => void;
    onFeedbackClick?: () => void;
    onChooseDateClick?: () => void;
    onRejectClick?: () => void;
}

const AppointmentCard: React.FunctionComponent<AppointmentCardProps> = ({
    appointment,
    onEditClick,
    onRescheduleClick,
    onRetakeQuestionnaireClick,
    onSchedulePhoneAppointmentClick,
    onScheduleRetakeClick,
    onFeedbackClick,
    onChooseDateClick,
    onRejectClick,
}: AppointmentCardProps) => {
    if (isAppointment(appointment)) {
        return (
            <ScreeningAppointmentCard
                appointment={appointment}
                onEditClick={onEditClick}
                onRescheduleClick={onRescheduleClick}
                onRetakeQuestionnaireClick={onRetakeQuestionnaireClick}
                onSchedulePhoneAppointmentClick={
                    onSchedulePhoneAppointmentClick
                }
                onScheduleRetakeClick={onScheduleRetakeClick}
                onFeedbackClick={onFeedbackClick}
            />
        );
    } else {
        return (
            <PhoneAppointmentCard
                phoneAppointment={appointment}
                onChooseDateClick={onChooseDateClick}
                onRejectClick={onRejectClick}
            />
        );
    }
};

export default AppointmentCard;
