import React from "react";
import { Plus, Minus, AlertTriangle, X } from "react-feather";
import ChosenMedicalTest from "../../models/chosen-medical-test";
import { getLocaleName } from "../../models/medical-test";
import useLanguage from "../../hooks/language";

import styles from "./medical-result-row.module.css";

interface MedicalResultRowProps {
    test: ChosenMedicalTest;
    canceled?: boolean;
}

const MedicalResultRow: React.FunctionComponent<MedicalResultRowProps> = ({
    test,
    canceled = false,
}: MedicalResultRowProps) => {
    const { language, translations } = useLanguage();
    const t = translations.resultsSection;

    // Rendering

    let statusText = t.invalidResultLabel;
    let icon = null;
    let className = null;

    switch (test.result) {
        case "ABNORMAL":
            statusText = t.positiveResultLabel;
            icon = <Plus />;
            className = styles.positive;
            break;
        case "NORMAL":
            statusText = t.negativeResultLabel;
            icon = <Minus />;
            break;
        case "RETAKE":
            statusText = t.toRetakeResultLabel;
            icon = <AlertTriangle />;
            className = styles.retake;
            break;
        case "UNDISCLOSED":
            statusText = t.undisclosedResultLabel;
            break;
    }

    if (test.status === "REFUSED") {
        statusText = t.notDoneResultLabel;
        icon = <X />;
        className = styles.refused;
    }

    if (canceled) {
        statusText = t.cancelled;
        icon = <X />;
        className = null;
    }

    return (
        <div className={`${styles.holder} ${className ?? ""}`}>
            {getLocaleName(test.medicalTest, language)}
            <div>
                {statusText}
                <div className={styles.iconHolder}>{icon}</div>
            </div>
        </div>
    );
};

export default MedicalResultRow;
