import React from "react";
import * as R from "ramda";
import { DateTime } from "luxon";
import useLanguage from "../../hooks/language";

interface PersonalRecommendationsProps {
    userQuestionnaire: any;
}

const PersonalRecommendations: React.FunctionComponent<PersonalRecommendationsProps> = ({
    userQuestionnaire,
}: PersonalRecommendationsProps) => {
    const { translations, language } = useLanguage();
    const t = translations.recommendationsSection;

    // Helpers

    const isSuggested = (field: string) =>
        R.pathOr(false, [field], userQuestionnaire);

    const hasScreeningSuggestion =
        isSuggested("suggestHIVScreening") ||
        isSuggested("suggestHepatiteCScreening") ||
        isSuggested("suggestSyphilisScreening");

    const dateSuggestionFormatter = (field: string) =>
        DateTime.fromISO(R.pathOr("", [field], userQuestionnaire))
            .setLocale(language)
            .toLocaleString(DateTime.DATE_MED);

    // Rendering

    return (
        <div style={{ textAlign: "left" }}>
            {isSuggested("suggestCondom") && <p>{t.condomRecommendation}</p>}

            {isSuggested("warnAboutDrug") && (
                <p>{t.drugSharingRecommendation}</p>
            )}

            {isSuggested("suggestPPE") && (
                <p>
                    {t.ppeRecommendation}{" "}
                    <a target="_blank" rel="noreferrer">
                        {t.ppeLink}
                    </a>
                </p>
            )}

            {isSuggested("suggestPREP") && (
                <p>
                    {t.prepRecommendation}{" "}
                    <a target="_blank" rel="noreferrer">
                        {t.prepLink}
                    </a>{" "}
                    {t.prepRecommendation2}
                </p>
            )}

            {isSuggested("suggestVax") && <p>{t.vaxRecommendation}</p>}

            {hasScreeningSuggestion && (
                <React.Fragment>
                    <p>{t.retakeRecommendationHeader}</p>
                    <ul>
                        {isSuggested("suggestHIVScreening") && (
                            <li>
                                {t.retakeRecommendationHIV}{" "}
                                {dateSuggestionFormatter("suggestHIVScreening")}
                            </li>
                        )}
                        {isSuggested("suggestHepatiteCScreening") && (
                            <li>
                                {t.retakeRecommendationHepatiteC}{" "}
                                {dateSuggestionFormatter(
                                    "suggestHepatiteCScreening"
                                )}
                            </li>
                        )}
                        {isSuggested("suggestSyphilisScreening") && (
                            <li>
                                {t.retakeRecommendationSyphilis}{" "}
                                {dateSuggestionFormatter(
                                    "suggestSyphilisScreening"
                                )}
                            </li>
                        )}
                    </ul>
                    <p className="p-default-style results-explanation">
                        {t.retakeRecommendationExplanation}
                    </p>
                </React.Fragment>
            )}
        </div>
    );
};

export default PersonalRecommendations;
