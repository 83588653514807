import React, { useState, useEffect } from "react";
import styles from "./popover.module.css";

type PopoverAlignement = "left" | "right";

type PopoverPosition = "top" | "bottom";

interface PopoverInterface {
    children?: React.ReactElement;
    className?: string;
    shown?: boolean;
    align?: PopoverAlignement;
    position?: PopoverPosition;
    onChange?: (shown: boolean) => void;
}

const Popover: React.FunctionComponent<PopoverInterface> = ({
    children,
    className,
    align = "left",
    position = "top",
    shown = false,
    onChange,
}: PopoverInterface) => {
    // States and ref

    const [_shown, setShown] = useState(shown);

    // Effects
    useEffect(() => {
        if (shown) {
            window.addEventListener("click", hide);
        } else {
            window.removeEventListener("click", hide);
        }

        setShown(shown);
    }, [shown]);

    useEffect(() => {
        if (onChange) onChange(_shown);
    }, [_shown]);

    // Handlers

    const hide = () => {
        setShown(false);
    };

    // Rendering

    let rootClass = styles.popover;
    if (className) rootClass += ` ${className}`;
    if (_shown) rootClass += ` ${styles.shown}`;

    const rootStyle = {
        right: align === "right" ? 0 : undefined,
        left: align === "left" ? 0 : undefined,
        transform:
            position === "top"
                ? "translateY(calc(-100% - 30px))"
                : "translateY(20px)",
    };

    const arrowStyle = {
        right: align === "right" ? 20 : undefined,
        left: align === "left" ? 20 : undefined,
        bottom: position === "top" ? -12 : undefined,
    };

    return (
        <div className={rootClass} style={rootStyle}>
            <div className={styles.arrow} style={arrowStyle} />
            <div className={styles.content}>
                <div
                    className={styles.inner}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Popover;
