import { Tag } from "antd";
import useLanguage from "hooks/language";
import { DeliveryStatus } from "models/delivery";
import { FunctionComponent } from "react";
import styles from "./delivery-tag.module.css";
import { mapStatusToDotStyle, mapStatusToTagStyle } from "./utils";

type DeliveryTag = {
    status: DeliveryStatus;
};

export const DeliveryTag: FunctionComponent<DeliveryTag> = ({
    status,
}: DeliveryTag): JSX.Element => {
    const {
        translations: {
            prepSection: {
                prepDelivery: {
                    active: { status: t },
                },
            },
        },
    } = useLanguage();

    return (
        <Tag className={styles.tagStyle} style={mapStatusToTagStyle(status)}>
            <span
                className={styles.dotStyle}
                style={mapStatusToDotStyle(status)}
            />
            {t[status]}
        </Tag>
    );
};
