import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Loader } from "react-feather";
import { Button } from "../../components";
import useLanguage from "../../hooks/language";
import useAuthToken from "../../hooks/auth-token";
import ROUTES from "../../utils/routes";
import ENDPOINTS from "../../utils/endpoints";
import { Footer, Header } from "../../components";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

const EmailConfirmationPage: React.FunctionComponent = () => {
    const { params } = useRouteMatch();
    const { push } = useHistory();
    const setAuthToken = useAuthToken()[1];
    const { translations } = useLanguage();
    const t = translations.emailConfirmation;
    const [analytics] = useAnalytics();

    // State

    const [success, setSuccess] = useState<null | boolean>(null);

    // Effects

    useEffect(() => {
        if (success === null) {
            confirmEmail();
        }
    }, [params]);

    useEffect(() => {
        if (success) {
            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.EmailVerificationCompleted);
        }
    }, [success]);
    // Network

    const confirmEmail = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { token } = params;

        try {
            const { data } = await Axios.put(ENDPOINTS.CONFIRM_EMAIL, {
                token,
            });
            const { token: userToken } = data;
            setSuccess(true);
            setAuthToken(userToken);
        } catch (e) {
            setSuccess(false);
        }
    };

    // Rendering

    return (
        <div className="page">
            <Header />
            <div className="preauth-container">
                <h2>{t.header}</h2>
                {success === null && <Loader className="spinorama" />}
                {success === false && (
                    <p
                        className="has-error"
                        dangerouslySetInnerHTML={{
                            __html: t.invalidTokenError,
                        }}
                    />
                )}
                {success === true && (
                    <div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t.successMessage,
                            }}
                        />
                        <Button
                            type="secondary"
                            onClick={() => push(ROUTES.HOME)}
                        >
                            {t.goToHomeButton}
                        </Button>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default EmailConfirmationPage;
