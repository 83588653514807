// Uses Rudderstack v2.48 which is not implemented in Typescript
import * as RudderAnalytics from "rudder-sdk-js";
import { toUnixTime } from "utils/date";
import { ICDPAnalyticsService } from "..";
import { UserActionEvent } from "../events";
import { generateAnalyticsUserID } from "../user-id";

export interface IRudderstackConfig {
    writeKey: string;
    dataplaneURL: string;
}

export class RudderStackAnalytics implements ICDPAnalyticsService {
    public constructor(
        private readonly writeKey: string,
        private readonly dataPlaneURL: string
    ) {}

    public init(): void {
        if (!this.isValidConfiguration()) {
            return;
        }

        RudderAnalytics.load(this.writeKey, this.dataPlaneURL);
        RudderAnalytics.ready(() => {
            console.debug("Rudderstack SDK set up");
        });
    }

    public identifyAnonymousUser<T>(properties?: T): void {
        if (!this.isValidConfiguration()) {
            return;
        }

        RudderAnalytics.identify(
            (properties as unknown) as RudderAnalytics.apiObject
        );
    }

    public identifyUser(
        firstName: string,
        email: string,
        phoneNumber: string,
        language: string,
        nextAppointmentAt: string,
        lastAppointmentAt: string,
        accountCreatedAt: string
    ): void {
        if (!this.isValidConfiguration()) {
            return;
        }

        const userID = generateAnalyticsUserID(email);
        const traits: any = {
            firstName: firstName,
            email: email,
            phoneNumber: phoneNumber,
        };

        if (nextAppointmentAt) {
            traits.nextAppointmentAt = toUnixTime(nextAppointmentAt);
        }

        if (lastAppointmentAt) {
            traits.lastAppointmentAt = toUnixTime(lastAppointmentAt);
        }

        if (accountCreatedAt) {
            traits.created_at = toUnixTime(accountCreatedAt);
        }

        if (language) {
            traits.language = language;
        }

        RudderAnalytics.identify(
            userID.toString(),
            { ...traits },
            undefined,
            () => console.debug(`Used ${userID} identified`)
        );
    }

    public trackEvent<T>(
        eventName: UserActionEvent,
        eventProperties?: T
    ): void {
        if (!this.isValidConfiguration()) {
            return;
        }

        if (!eventProperties || Object.keys(eventProperties).length === 0) {
            RudderAnalytics.track(eventName, () =>
                console.debug(`Event ${eventName} tracked`)
            );

            return;
        }

        RudderAnalytics.track(
            eventName,
            (eventProperties as unknown) as RudderAnalytics.apiObject,
            undefined,
            () => console.debug(`Event ${eventName} tracked`)
        );
    }

    public disconnectUser(): void {
        RudderAnalytics.reset();
    }

    private isValidConfiguration(): boolean {
        const isValidConfig =
            !!this.writeKey &&
            !!this.dataPlaneURL &&
            this.dataPlaneURL.endsWith(".dataplane.rudderstack.com");

        if (!isValidConfig) {
            console.error("Invalid RudderStack Configuration");
        }

        return isValidConfig;
    }
}
