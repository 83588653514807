import useLanguage from "../../../../../../../hooks/language";
import {
    DeliveryDestination,
    DeliveryDestinationOptions,
    DeliveryFrequency,
    DeliveryFrequencyOptions,
} from "../../../../../../../models/delivery-settings";

import "./select-details.css";

interface SelectDetailsProps {
    destination?: DeliveryDestination;
    frequency?: DeliveryFrequency;
}

export const SelectDetails = ({
    destination,
    frequency,
}: SelectDetailsProps): JSX.Element => {
    const {
        translations: {
            prepSection: { deliverySettingsForm: t },
        },
    } = useLanguage();

    const isDestinationDetailsVisible =
        destination === DeliveryDestinationOptions.POST_PICK_UP;
    const isFrequencyDetailsVisible =
        frequency === DeliveryFrequencyOptions.ON_DEMAND ||
        frequency === DeliveryFrequencyOptions.ON_PAUSE;

    return (
        <span className="details">
            {isDestinationDetailsVisible &&
                t.destination.options[destination].details}
            {isFrequencyDetailsVisible &&
                t.frequency.options[frequency].details}
        </span>
    );
};
