import React from "react";
import useLanguage from "../../hooks/language";

import styles from "./language-picker.module.css";

interface LanguagePickerProps {
    className?: string;
}

const LanguagePicker: React.FunctionComponent<LanguagePickerProps> = ({
    className,
}: LanguagePickerProps) => {
    const { language, setLanguage } = useLanguage();

    const otherLanguage = language === "fr" ? "en" : "fr";

    return (
        <button
            className={`${styles.button} ${className ?? ""}`}
            onClick={() => setLanguage(otherLanguage)}
        >
            {otherLanguage}
        </button>
    );
};

export default LanguagePicker;
