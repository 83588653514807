import React from "react";
import { Loader } from "react-feather";

import styles from "./button.module.css";

type ButtonType = "primary" | "secondary";

interface ButtonProps {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    type?: ButtonType;
    onClick?: () => void;
}

const Button: React.FunctionComponent<ButtonProps> = ({
    children,
    className,
    type = "primary",
    disabled = false,
    loading = false,
    onClick,
}: ButtonProps) => {
    // Handlers

    const _onClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        if (onClick) onClick();

        event.preventDefault();
        event.stopPropagation();
    };

    // Rendering

    let rootClass = styles.button;
    if (type === "secondary") rootClass += ` ${styles.secondary}`;
    if (className) rootClass += ` ${className}`;

    return (
        <button
            disabled={disabled || loading}
            className={rootClass}
            onClick={_onClick}
        >
            {loading && <Loader className="spinorama" />}
            <span>{children}</span>
        </button>
    );
};

export default Button;
