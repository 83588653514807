import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentForm from "./form";
import useLanguage from "../../../../hooks/language";

const PUBLIC_STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY || "null";
const stripePromise = loadStripe(PUBLIC_STRIPE_KEY);

interface PaymentProps {
    onCompletePayment?: () => void;
}

const Payment: React.FunctionComponent<PaymentProps> = ({
    onCompletePayment,
}: PaymentProps) => {
    const { translations, language } = useLanguage();
    const t = translations.paymentSection;

    // Rendering

    return (
        <div className="container">
            <h2>{t.header}</h2>
            <p dangerouslySetInnerHTML={{ __html: t.noRefundWarning }} />
            <p>
                <b>{t.chargeExplanation}</b>
            </p>

            <Elements stripe={stripePromise} options={{ locale: language }}>
                <PaymentForm onCompletePayment={onCompletePayment} />
            </Elements>
        </div>
    );
};

export default Payment;
