import { useNextDelivery } from "../../utils/context";
import { collapseItem } from "../components/collapse-item";
import { CustomCollapse } from "../components/custom-collapse";

export const NextDelivery = (): JSX.Element | null => {
    const delivery = useNextDelivery();
    if (!delivery) return null;

    return <CustomCollapse items={[collapseItem(delivery, "1")]} />;
};
