import { createContext } from "react";
import User from "../models/user";
import { getTranslations } from "../translations";
import { IAnalyticsService } from "../utils/analytics";
import { IFeatureManager } from "../utils/feature-management";
import {
    getPersistedAuthToken,
    getPersistedUserLanguage,
    persistAuthToken,
    persistUserLanguage,
} from "../utils/local-storage";
import { UserLanguage } from "../utils/types";

interface AppContextInterface {
    authToken: string | null;
    setAuthToken: (token: string | null) => void;
    authUser: User | null;
    setAuthUser: (user: User | null) => void;
    language: UserLanguage;
    setLanguage: (language: UserLanguage) => void;
    translations: any; // Any is not so sexy but it's kind of legacy stuff from old code
    analytics: IAnalyticsService | null;
    setAnalytics: (service: IAnalyticsService) => void;
    featureManager: IFeatureManager | null;
    setFeatureManager: (service: IFeatureManager) => void;
}

const context: AppContextInterface = {
    authToken: getPersistedAuthToken(),
    setAuthToken: (token: string | null) => persistAuthToken(token),
    authUser: null,
    setAuthUser: () => console.log("Not implemented yet"),
    language: getPersistedUserLanguage(),
    setLanguage: persistUserLanguage,
    translations: getTranslations(),
    analytics: null,
    setAnalytics: () => console.log("Not implemented"),
    featureManager: null,
    setFeatureManager: () => console.log("Not implemented"),
};

const AppContext = createContext(context);

export default AppContext;
