/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import useLanguage from "../../hooks/language";

import styles from "./footer.module.css";

const Footer: React.FunctionComponent = () => {
    const { translations } = useLanguage();
    const t = translations.footer;

    // Rendering

    return (
        <div className={styles.footerContainer}>
            <div className={styles.footer}>
                <hr />
                <div className={styles.row}>
                    <strong>{t.needHelp}</strong>
                    <a href="mailto:info@prelib.com">info@prelib.com</a>
                    <span>{t.or}</span>
                    <a href="tel:5145351475">(514) 535-1475</a>
                </div>
                <div className={styles.row}>
                    <a href={t.termsAndConditionsLink}>
                        {t.termsAndConditions}
                    </a>
                    <span>-</span>
                    <a href={t.privacyPolicyLink}>{t.privacyPolicy}</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
