import useLanguage from "hooks/language";
import styles from "./tab-header.module.css";

export const TabHeader = (): JSX.Element => {
    const {
        translations: {
            prepSection: {
                prepDelivery: {
                    active: { tabHeader: t },
                },
            },
        },
    } = useLanguage();

    return (
        <div className={styles.tabHeader}>
            <div className={styles.deliveryDate}>{t.deliveryDate}</div>
            <div className={styles.status}>{t.status}</div>
            <div className={styles.address}>{t.address}</div>
            <div className={styles.actions}>{t.actions}</div>
        </div>
    );
};
