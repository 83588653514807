import React from "react";
import styles from "./feedback-questionnaire.module.css";
import RatingInput from "./rating-input";
import useLanguage from "../../hooks/language";
import Field from "../field";
import BooleanInput from "./boolean-input";
export type SatisfactionForm = {
    satisfactionLevel: string | null;
    recommendationProbability: string | null;
    recommendationJustification: string | null;
    phoneCommunicationAgreement: boolean | null;
};

interface FeedbackQuestionnaireProps {
    onSubmit?: () => void;
    isLoading?: boolean;
    onChange?: (answers: SatisfactionForm) => void;
}
const FeedbackQuestionnaire: React.FunctionComponent<FeedbackQuestionnaireProps> = ({
    onChange,
}: FeedbackQuestionnaireProps) => {
    // State
    const { translations } = useLanguage();
    const t = translations.appointmentsSection.feedbackModal;

    const [
        questionnaireAnswers,
        setQuestionnaireAnswers,
    ] = React.useState<SatisfactionForm>({
        phoneCommunicationAgreement: null,
        recommendationJustification: null,
        recommendationProbability: null,
        satisfactionLevel: null,
    });

    // Handling

    const onAnswersChange = ({
        satisfactionLevel = questionnaireAnswers.satisfactionLevel,
        recommendationProbability = questionnaireAnswers.recommendationProbability,
        recommendationJustification = questionnaireAnswers.recommendationJustification,
        phoneCommunicationAgreement = questionnaireAnswers.phoneCommunicationAgreement,
    }) => {
        const form: SatisfactionForm = {
            satisfactionLevel,
            recommendationProbability,
            recommendationJustification,
            phoneCommunicationAgreement,
        };

        setQuestionnaireAnswers(form);
        if (onChange) onChange(form);
    };

    // Rendering

    return (
        <div className={styles.satisfactionQuestionnaire}>
            <div className={styles.questionBlock}>
                <p>{t.satisfactionLevel}</p>
                <RatingInput
                    inputKey={"satisfactionLevel"}
                    onChange={onAnswersChange}
                />
            </div>
            <div className={styles.divider}>
                <span />
            </div>
            <div className={styles.questionBlock}>
                <p>{t.recommendationProbability}</p>
                <RatingInput
                    inputKey={"recommendationProbability"}
                    onChange={onAnswersChange}
                />
            </div>
            <div className={styles.divider}>
                <span />
            </div>
            <div className={styles.questionBlock}>
                <p>{t.recommendationJustification}</p>
                <Field
                    textArea={true}
                    placeholder={t.textAreaPlaceholder}
                    rows={3}
                    onChange={(e) =>
                        onAnswersChange({
                            recommendationJustification: e.target.value,
                        })
                    }
                />
            </div>
            <div className={styles.divider}>
                <span />
            </div>

            <div className={styles.questionBlock}>
                <p>{t.phoneCommunicationAgreement}</p>
                <BooleanInput
                    onChange={(value) =>
                        onAnswersChange({ phoneCommunicationAgreement: value })
                    }
                />
            </div>
            <div className={styles.divider}>
                <span />
            </div>
        </div>
    );
};

export default FeedbackQuestionnaire;
