export default interface Answer {
    id?: number;
    rawValue: string;
    questionId?: number;
    specialAttribute?: string;
    frTranslation: string;
    enTranslation: string;
    order?: number;
}

export const extractLocaleAnswer = (
    { frTranslation, enTranslation }: Answer,
    language: string
): string => {
    return language === "en" ? enTranslation : frTranslation;
};

export const sortAnswersPredicate = (a: Answer, b: Answer): number => {
    if (!a.order) return -1;
    if (!b.order) return 1;
    return a.order < b.order ? -1 : 1;
};
