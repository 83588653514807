import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Axios from "axios";
import {
    Footer,
    Header,
    Button,
    Checkbox,
    Field,
    Modal,
} from "../../components";
import { ConfirmPhoneNumberModal } from "../../modals";
import { PHONE_NUMBER_REGEX } from "../../utils/validation";
import useLanguage from "../../hooks/language";
import { TfaPreference } from "../../models/user";
import ENDPOINTS from "../../utils/endpoints";
import useAuthUser from "../../hooks/auth-user";
import ROUTES from "../../utils/routes";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

const TfaSettingsPage: React.FunctionComponent = () => {
    const { push } = useHistory();
    const [authUser, setAuthUser] = useAuthUser();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { translations } = useLanguage();
    const t = translations.tfaSettings;
    const tModal = translations.phoneConfirmationModal;
    const [analytics] = useAnalytics();

    // States

    const [tfaPreference, setTfaPreference] = useState<
        undefined | TfaPreference
    >();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(
        false
    );
    const [phoneConfirmed, setPhoneConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);

    // Effects

    useEffect(() => {
        if (authUser) setPhoneNumber(authUser.phone);
    }, [authUser]);

    useEffect(() => {
        setPhoneConfirmed(false);
    }, [phoneNumber]);

    // Validation

    const phoneNumberError = !PHONE_NUMBER_REGEX.test(phoneNumber)
        ? t.phoneNumberError
        : null;

    // Handlers

    const onClickTfaCheckbox = (pref: TfaPreference) => {
        if (pref !== tfaPreference) {
            setTfaPreference(pref);

            const eventProperies = { method: pref };

            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.MFASetupInitiated, eventProperies);
        } else {
            setTfaPreference(undefined);
        }
    };

    const onConfirmPhoneNumber = () => {
        setPhoneConfirmed(true);
        setConfirmationModalVisible(false);

        if (!authUser) return;
        setAuthUser({ ...authUser, phone: phoneNumber });
    };

    // Network

    const setupTfa = async () => {
        if (!authUser) return;

        setLoading(true);

        const tfaSettingsToken =
            searchParams.get("tfaSettingsToken") || undefined;
        const body = {
            tfaPreference,
            tfaSettingsToken,
        };

        try {
            await Axios.post(ENDPOINTS.SET_TFA_SETTINGS, body);

            const eventProperties = {
                type: "tfaPreference",
                method: tfaPreference,
            };
            analytics
                ?.cdp()
                ?.trackEvent(UserActionEvent.LoginCompleted, eventProperties);
            analytics
                ?.web()
                ?.trackEvent(UserActionEvent.LoginCompleted, eventProperties);

            setAuthUser({ ...authUser, tfaPreference });
            push(ROUTES.HOME);
        } catch {
            setLoading(false);
        }
    };

    // Rendering

    const isFetchingData = !authUser;
    const prefIsSms = tfaPreference === "sms";

    return (
        <div>
            {/* Phone confirmation modal */}
            <Modal
                title={tModal.title}
                visible={confirmationModalVisible}
                onVisibilityChange={setConfirmationModalVisible}
            >
                <ConfirmPhoneNumberModal
                    visible={confirmationModalVisible}
                    phoneNumber={phoneNumber}
                    onConfirm={onConfirmPhoneNumber}
                />
            </Modal>

            <div className="page">
                <Header />

                <div className="preauth-container">
                    <h2>{t.title}</h2>
                    <p>{t.subtitle}</p>
                    <div className="input-holder">
                        <Checkbox
                            checked={tfaPreference === "email"}
                            onChange={() => onClickTfaCheckbox("email")}
                        >
                            {t.byEmail}
                        </Checkbox>
                        <Checkbox
                            checked={tfaPreference === "sms"}
                            onChange={() => onClickTfaCheckbox("sms")}
                        >
                            {t.bySms}
                        </Checkbox>
                    </div>
                    {tfaPreference === "sms" && (
                        <div className="input-holder">
                            <label>{t.phoneNumberLabel}</label>
                            <Field
                                placeholder={t.phoneNumberPlaceholder}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                onPressEnter={() =>
                                    setConfirmationModalVisible(true)
                                }
                                error={phoneNumberError}
                                loading={isFetchingData}
                            />
                            <Button
                                onClick={() =>
                                    setConfirmationModalVisible(true)
                                }
                                disabled={
                                    phoneNumberError !== null || phoneConfirmed
                                }
                                type="secondary"
                                loading={isFetchingData}
                            >
                                {t.phoneConfirmationButton}
                            </Button>
                        </div>
                    )}
                    <Button
                        disabled={
                            (prefIsSms && !phoneConfirmed) || !tfaPreference
                        }
                        loading={isFetchingData || loading}
                        onClick={setupTfa}
                    >
                        {t.confirmButton}
                    </Button>
                </div>

                <Footer />
            </div>
        </div>
    );
};

export default TfaSettingsPage;
