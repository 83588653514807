export interface DeliveryEditRequest {
    id: number;
    deliveryId: number;
    status: DeliveryEditRequestStatus;
    old: { date: string };
    new: { date: string };
    justification?: string | null;
    statusUpdatedByPharmacistAt?: string | null;
    statusUpdatedBy?: string | null;
}

export const DeliveryEditRequestStatuses = {
    ACCEPTED: "ACCEPTED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
} as const;

export type DeliveryEditRequestStatus = keyof typeof DeliveryEditRequestStatuses;
