import { Calendar } from "antd";
import localeFr from "antd/lib/date-picker/locale/fr_FR";
import { Button, Modal } from "components";
import dayjs from "dayjs";
import useAuthUser from "hooks/auth-user";
import useLanguage from "hooks/language";
import { Delivery, deliveryLimitDate } from "models/delivery";
import { useState } from "react";
import {
    useFutureDeliveries,
    useSelectedDelivery,
    useUpdateDelivery,
} from "../../../utils/context";

export const EditDateModal = ({
    editDateModalVisible,
    setEditDateModalVisible,
}: {
    editDateModalVisible: boolean;
    setEditDateModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element | null => {
    const delivery = useSelectedDelivery();
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
        delivery ? dayjs(delivery.date) : null
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const updateDelivery = useUpdateDelivery();
    const futureDeliveries = useFutureDeliveries();
    const [user] = useAuthUser();
    const { language } = useLanguage();
    const {
        translations: {
            prepSection: {
                prepDelivery: {
                    active: { editDate: t },
                },
            },
        },
    } = useLanguage();

    const onsubmit = async () => {
        if (!selectedDate) return;
        setIsLoading(true);
        delivery &&
            updateDelivery &&
            (await updateDelivery(
                {
                    date: selectedDate?.format(),
                },
                delivery.id
            ));
        setEditDateModalVisible(false);
        setIsLoading(false);
    };

    // helper functions
    const isDateDisabled = (date: dayjs.Dayjs) =>
        date.isBefore(deliveryLimitDate()) ||
        date.isAfter(user?.lastPrepStatus?.eligibilityEndDate) ||
        isDateBookedForFutureDelivery(date, futureDeliveries);

    const isDateBookedForFutureDelivery = (
        date: dayjs.Dayjs,
        deliveries: Delivery[]
    ): boolean =>
        deliveries.some(
            (delivery) =>
                dayjs(delivery.date).isAfter(dayjs()) &&
                dayjs(date).format("DD/MM/YYYY") ===
                    dayjs(delivery.date).format("DD/MM/YYYY")
        );

    return (
        <Modal
            title={t.title}
            subtitle={t.description}
            visible={editDateModalVisible}
            onVisibilityChange={setEditDateModalVisible}
        >
            <Calendar
                onSelect={setSelectedDate}
                disabledDate={isDateDisabled}
                defaultValue={dayjs(delivery?.date)}
                fullscreen={false}
                locale={language.includes("fr") ? localeFr : undefined}
            />
            <br />
            <br />
            <div className="dual-button-holder">
                <Button
                    type="secondary"
                    onClick={() => setEditDateModalVisible(false)}
                >
                    {t.reject}
                </Button>
                <Button loading={isLoading} onClick={onsubmit}>
                    {t.continue}
                </Button>
            </div>
        </Modal>
    );
};
