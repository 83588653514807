import { useState, useEffect } from "react";

interface ScrollHook {
    scrollX: number;
    scrollY: number;
}

/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY } = useScroll()
 */
const useScroll = (): ScrollHook => {
    const setLastScrollTop = useState(0)[1];
    const [bodyOffset, setBodyOffset] = useState(
        document.body.getBoundingClientRect()
    );
    const [scrollY, setScrollY] = useState(bodyOffset.top);
    const [scrollX, setScrollX] = useState(bodyOffset.left);

    const listener = () => {
        setBodyOffset(document.body.getBoundingClientRect());
        setScrollY(-bodyOffset.top);
        setScrollX(bodyOffset.left);
        setLastScrollTop(-bodyOffset.top);
    };

    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        };
    });

    return {
        scrollY,
        scrollX,
    };
};

export default useScroll;
