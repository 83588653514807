import dayjs from "dayjs";
import { DateTime } from "luxon";
import Appointment from "./appointment";
import {
    DeliveryEditRequest,
    DeliveryEditRequestStatus,
    DeliveryEditRequestStatuses,
} from "./delivery-edit-request";
import {
    DeliveryDestination,
    DeliveryFrequency,
    DeliveryFrequencyOptions,
} from "./delivery-settings";
import User, {
    hasUpcomingDeliveryAtPrelib,
    upcomingScreeningAppointment,
} from "./user";

export interface Delivery {
    id: number;
    status: DeliveryStatus;
    deliveryProfile: DeliveryFrequency;
    date: string;
    destination: DeliveryDestination;
    pickUpAtAppointment?: boolean | null;
    address?: string;
    city?: string | null;
    zipCode?: string | null;
    deliveryEditRequests?: DeliveryEditRequest[];
    hasRequestInProgress: boolean;
    statusUpdatedByPharmacistAt?: string | null;
    justification?: string | null;
    prepQty?: DeliveryQuantity | null;
}

export const STARTING_PREP_DELIVERY_YEAR = 2024;

export const DeliveryStatusOptions = {
    PENDING: "PENDING",
    REQUEST_IN_PROGRESS: "REQUEST_IN_PROGRESS",
    REJECTED: "REJECTED",
    CANCELED: "CANCELED",
    CONFIRMED: "CONFIRMED",
    DELIVERED: "DELIVERED",
} as const;
export type DeliveryStatus = keyof typeof DeliveryStatusOptions;

export const DeliveryQuantityOptions = {
    ONE_MONTH: DeliveryFrequencyOptions.ONE_MONTH,
    THREE_MONTHS: DeliveryFrequencyOptions.THREE_MONTHS,
} as const;
type DeliveryQuantity = keyof typeof DeliveryQuantityOptions;

const INCOMPLETE_DELIVERY_STATUSES = [
    DeliveryStatusOptions.REQUEST_IN_PROGRESS,
    DeliveryStatusOptions.CONFIRMED,
    DeliveryStatusOptions.PENDING,
] as const;
type IncompleteDeliveryStatuses = typeof INCOMPLETE_DELIVERY_STATUSES[number];

export const NON_EDITABLE_STATUSES = [
    DeliveryStatusOptions.CANCELED,
    DeliveryStatusOptions.DELIVERED,
    DeliveryStatusOptions.REJECTED,
] as const;
export type NonEditableDeliveryStatuses = typeof NON_EDITABLE_STATUSES[number];

export const DELIVERY_DATE_LIMIT_HOURS = 72;

/**
 * Calculates the delivery limit date.
 * Adds a predefined number of hours (DELIVERY_DATE_LIMIT_HOURS) to the current date time,
 * and returns the resulting date.
 * @returns {Date} The delivery limit date as a js Date object.
 */
export const deliveryLimitDate = (): Date =>
    DateTime.now().plus({ hours: DELIVERY_DATE_LIMIT_HOURS }).toJSDate();

export const isDeliveryWithinLimitHours = (delivery: Delivery): boolean =>
    DateTime.fromISO(delivery.date).toLocal() <=
    DateTime.fromJSDate(deliveryLimitDate());

export const isInThePast = (delivery: Delivery): boolean =>
    DateTime.fromISO(delivery.date).toLocal().startOf("day") <
    DateTime.now().startOf("day");

export const requestInProgress = (
    delivery: Delivery
): DeliveryEditRequest | undefined =>
    delivery.deliveryEditRequests?.find(
        (der: DeliveryEditRequest) =>
            der.status === DeliveryEditRequestStatuses.PENDING
    );

export const hasUpcomingIncompleteDelivery = (
    deliveries?: Delivery[]
): boolean => {
    if (!deliveries) return false;
    return deliveries?.some(
        (delivery) =>
            DateTime.fromISO(delivery.date).toLocal() > DateTime.now() &&
            INCOMPLETE_DELIVERY_STATUSES.includes(
                delivery.status as IncompleteDeliveryStatuses
            )
    );
};

/**
 * Retrieves the most recent DeliveryEditRequest based on the provided statuses.
 * @param {Delivery} delivery The delivery object containing the edit requests.
 * @param {DeliveryEditRequestStatus[]} [deliveryEditRequestStatuses] Array of statuses to filter the requests.
 * @returns {DeliveryEditRequest | undefined} The most recent request matching the specified statuses, or undefined if none match.
 */
export const extractMostRecentDERBasedOnStatuses = (
    delivery: Delivery,
    deliveryEditRequestStatuses: DeliveryEditRequestStatus[]
): DeliveryEditRequest | null => {
    // Ensure there are edit requests available
    if (
        !delivery.deliveryEditRequests ||
        delivery.deliveryEditRequests.length === 0
    ) {
        return null;
    }

    // Filter requests based on provided statuses
    const validDERs = delivery.deliveryEditRequests.filter(
        (request: DeliveryEditRequest) =>
            deliveryEditRequestStatuses.includes(request.status)
    );

    if (validDERs.length === 0) {
        return null;
    }

    return validDERs.reduce(
        (mostRecent: DeliveryEditRequest, current: DeliveryEditRequest) =>
            current.id > mostRecent.id ? current : mostRecent
    );
};

/**
 * Determines the most recent entity between a delivery and an optional delivery edit request based on update date hour.
 * @param {Delivery} delivery The delivery object.
 * @param {DeliveryEditRequest} [deliveryEditRequest] Optional delivery edit request object.
 * @returns {Delivery | DeliveryEditRequest} The most recent entity based on the latest update date hour.
 */
export const getEntityToConsider = (
    delivery: Delivery,
    deliveryEditRequest: DeliveryEditRequest
): Delivery | DeliveryEditRequest => {
    return dayjs(delivery.statusUpdatedByPharmacistAt).isAfter(
        deliveryEditRequest.statusUpdatedByPharmacistAt
    )
        ? delivery
        : deliveryEditRequest;
};

/**
 * Checks if the given delivery is the closest to the upcoming screening appointment,
 * either just before or just after the screening date, among all future deliveries.
 *
 * @param {Appointment} upcomingScreeningAppointment The upcoming screening appointment to compare against
 * @param {Delivery} delivery The delivery to check
 * @param {Delivery[]} futureDeliveries Future deliveries
 * @returns {boolean} True if the delivery is the closest to the screening date, either just before or just after, otherwise false
 */
const isCloseToScreening = (
    upcomingScreeningAppointment: Appointment,
    delivery: Delivery,
    futureDeliveries: Delivery[]
) => {
    const screeningDate = dayjs(upcomingScreeningAppointment.datetime);
    const futureDeliveryDates = futureDeliveries
        .filter(
            (_delivery) =>
                dayjs(_delivery.date).isAfter(dayjs()) &&
                _delivery.status != DeliveryStatusOptions.REJECTED &&
                _delivery.status != DeliveryStatusOptions.CANCELED
        )
        .map((delivery) => dayjs(delivery.date));

    // Filter deliveries into those before and after the screening date
    const beforeScreening = futureDeliveryDates
        .filter((date) => date.isBefore(screeningDate))
        .sort((a, b) => b.diff(a));
    const afterScreening = futureDeliveryDates
        .filter((date) => date.isAfter(screeningDate))
        .sort((a, b) => a.diff(b));

    // Determine the closest delivery before and the closest delivery after the screening date
    const closestBefore =
        beforeScreening.length > 0 ? beforeScreening[0] : null;
    const closestAfter = afterScreening.length > 0 ? afterScreening[0] : null;

    // Check if the provided delivery date is the closest before or after the screening date
    return (
        (closestBefore && dayjs(delivery.date).isSame(closestBefore)) ||
        (closestAfter && dayjs(delivery.date).isSame(closestAfter))
    );
};

/**
 * Determines if a delivery can be made to a prelib location based on user screening appointment and future deliveries
 *
 * @param {User} user The user for whom the delivery is being checked
 * @param {Delivery} delivery The delivery to be checked
 * @param {Delivery[]} futureDeliveries Future deliveries
 * @returns {boolean} True if the delivery can be made to a prelib location, otherwise false
 */
export const canDeliverToPrelib = (
    user: User,
    delivery: Delivery,
    futureDeliveries: Delivery[]
): boolean => {
    const upcomingScreeningApt = upcomingScreeningAppointment(user);
    return !!(
        delivery.pickUpAtAppointment &&
        upcomingScreeningApt &&
        isCloseToScreening(upcomingScreeningApt, delivery, futureDeliveries) &&
        !hasUpcomingDeliveryAtPrelib(user, futureDeliveries)
    );
};
