import { DatePicker as BaseDatePicker, DatePickerProps } from "antd";
import styles from "./date-picker.module.css";

export const DatePicker = (props: DatePickerProps) => (
    <BaseDatePicker
        className={styles.picker}
        popupClassName={styles.popup}
        {...props}
    />
);
