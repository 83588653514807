import React, { useState } from "react";
import Axios from "axios";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";
import Appointment from "../../models/appointment";
import FeedbackQuestionnaire, {
    SatisfactionForm,
} from "../../components/feedback-questionnaire";
import { NotificationManager } from "react-notifications";
import ENDPOINTS from "../../utils/endpoints";
import useAuthUser from "../../hooks/auth-user";

interface FeedbackModalProps {
    onClose?: () => void;
    appointment?: Appointment;
}

const FeedbackModal: React.FunctionComponent<FeedbackModalProps> = ({
    onClose,
    appointment,
}: FeedbackModalProps) => {
    const { translations } = useLanguage();
    const t = translations.appointmentsSection;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [authUser, setAuthUser] = useAuthUser();
    const [answers, setAnswers] = useState<SatisfactionForm>({
        satisfactionLevel: null,
        recommendationProbability: null,
        recommendationJustification: null,
        phoneCommunicationAgreement: null,
    });

    const formIsFilled =
        answers.satisfactionLevel !== null &&
        answers.recommendationProbability !== null &&
        answers.phoneCommunicationAgreement !== null;
    // Rendering

    const onChange = (answers: SatisfactionForm) => {
        setAnswers(answers);
    };

    const onSubmit = async () => {
        try {
            if (!authUser) return;

            setIsLoading(true);
            await Axios.post(ENDPOINTS.USER_SATISFACTION_QUESTIONNAIRE, {
                ...answers,
                appointmentId: appointment?.id,
            });

            // update appointement canTakeSatisfactionQuestionnaire attribute
            const updateAppointment = authUser?.appointments;
            const appointmentIdx = updateAppointment?.findIndex(
                (x) => x.id == appointment?.id
            );

            updateAppointment[
                appointmentIdx
            ].canTakeSatisfactionQuestionnaire = false;
            setAuthUser({ ...authUser, appointments: updateAppointment });

            setIsLoading(false);

            if (onClose) onClose();

            NotificationManager.success(
                translations.notification.satisfactionQuestionnaireSent
            );

            //close modal
        } catch (error) {
            console.log(error);
            NotificationManager.error(error.message);
            setIsLoading(false);
        }
    };

    return (
        <div>
            <FeedbackQuestionnaire onChange={onChange} />
            <div className="dual-button-holder">
                <Button onClick={onClose} type="secondary">
                    {t.cancellationModal.exitButtonText}
                </Button>
                <Button
                    disabled={!formIsFilled}
                    loading={isLoading}
                    onClick={onSubmit}
                >
                    {t.cancellationModal.confirmButtonText}
                </Button>
            </div>
        </div>
    );
};

export default FeedbackModal;
