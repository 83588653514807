import React, { useRef, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Button } from "../index";
import Questionnaire, {
    extractQuestionnaireErrors,
    buildQuestionArray,
    QuestionnaireError,
} from "../../models/questionnaire";
import Question from "../../models/question";
import Answer from "../../models/answer";
import useScroll from "../../hooks/scroll";
import useLanguage from "../../hooks/language";

import QuestionBlock from "./question-block";
import Tracker from "./tracker";

import styles from "./questionnaire.module.css";

const QUESTION_BLOCK_HEIGHT = 400;
const SCROLL_OFFSET = 150;
interface QuestionnaireFormProps {
    questionnaire: Questionnaire;
    onCompleteQuestionnaire: (questionnaire: Questionnaire) => void;
    loading?: boolean;
}

const QuestionnaireForm: React.FunctionComponent<QuestionnaireFormProps> = ({
    questionnaire,
    onCompleteQuestionnaire,
    loading = false,
}: QuestionnaireFormProps) => {
    const { translations } = useLanguage();
    const t = translations.questionnaireComponent;
    const { scrollY } = useScroll();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const containerOffSet =
        containerRef &&
        containerRef.current &&
        containerRef.current.parentElement
            ? containerRef.current.parentElement.offsetTop -
              (containerRef.current.offsetTop -
                  containerRef.current.parentElement.offsetTop)
            : 0;
    const offsetRelativeToScroll =
        Math.floor(scrollY - containerOffSet) >= 0
            ? Math.floor(scrollY - containerOffSet)
            : 0;
    const focusedIndex = Math.floor(
        offsetRelativeToScroll / QUESTION_BLOCK_HEIGHT
    );

    // States

    const [submitted, setSubmitted] = useState(false);
    const [_questionnaire, setQuestionnaire] = useState(questionnaire);

    // Validation

    const errors = extractQuestionnaireErrors(questionnaire, translations);

    // Handlers

    const onAnswerChange = ({ id }: Question, answer: any | Answer[]) => {
        const index = _questionnaire.questions.findIndex(
            ({ id: _id }) => _id === id
        );
        if (index > -1) {
            if (
                _questionnaire.questions[index].type === "checkbox" &&
                Array.isArray(answer)
            ) {
                _questionnaire.questions[index].selectedAnswers = answer;
            } else {
                _questionnaire.questions[index].answer = answer;
            }
        }
        setQuestionnaire({ ..._questionnaire });
    };

    const scrollToIndex = (index: number) => {
        if (index === focusedIndex) return;
        scroll.scrollTo(
            index * QUESTION_BLOCK_HEIGHT + SCROLL_OFFSET / 2 + containerOffSet,
            {
                duration: 500,
                ignoreCancelEvents: true,
            }
        );
    };

    const onSubmitQuestion = () => {
        scrollToIndex(focusedIndex + 1);
    };

    const onSubmitForm = () => {
        setSubmitted(true);
        if (errors.length > 0) return;

        onCompleteQuestionnaire(_questionnaire);
    };

    // Rendering

    const showErrors = submitted && errors.length > 0;

    const renderError = ({
        error,
        questionIndex,
        index,
    }: QuestionnaireError) => {
        return (
            <a onClick={() => scrollToIndex(index)}>
                {questionIndex}. {error}
            </a>
        );
    };

    const questions = buildQuestionArray(questionnaire);

    const ramqExpDateQuestion = questions.find(
        (question) => question.key == "ramqExpDate"
    );
    const ramqNumberQuestion = questions.find(
        (question) => question.key == "ramqNumber"
    );
    if (ramqExpDateQuestion && ramqNumberQuestion) {
        if (
            typeof ramqNumberQuestion.answer.rawValue == "undefined" ||
            ramqNumberQuestion.answer.rawValue == ""
        )
            questions.splice(parseInt(ramqExpDateQuestion.index) - 1, 1);
    }
    return (
        <div ref={containerRef}>
            <div className={`${styles.holder} container`}>
                {questions.map((question, index) => (
                    <QuestionBlock
                        key={question.id}
                        question={question}
                        onChange={(answer: any) =>
                            onAnswerChange(question, answer)
                        }
                        focused={focusedIndex === index}
                        showError={submitted}
                        onSubmit={onSubmitQuestion}
                        onClick={() => scrollToIndex(index)}
                    />
                ))}
                <div className="dual-button-holder flex-end">
                    <Button
                        onClick={onSubmitForm}
                        disabled={showErrors}
                        loading={loading}
                    >
                        {t.completeBtn}
                    </Button>
                </div>

                {showErrors && (
                    <div className={`${styles.errorsHolder} has-error`}>
                        <label>{t.invalidFormError}</label>
                        {errors.map(renderError)}
                    </div>
                )}
            </div>
            <Tracker
                questions={questions}
                focusedIndex={focusedIndex}
                onClickQuestion={scrollToIndex}
                showErrors={submitted}
            />
        </div>
    );
};

export default QuestionnaireForm;
