import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import CalendarPicker from "./calendar";
import { Field } from "..";
import TimePicker from "./time-picker";
import useLanguage from "../../hooks/language";

import styles from "./datetime-select.module.css";

const DEFAULT_PICKER_MAX_HEIGHT = 500;

interface DatetimePickerProps {
    className?: string;
    openedDates?: string[];
    availableTimeSlots?: string[];
    date?: Date | undefined;
    minDate?: Date;
    maxDate?: Date;
    onDateChange?: (date: Date) => void;
    onTimeChange?: (time: string | undefined) => void;
    onCalendarNavigation?: (firstActiveDay: Date) => void;
}

const DatetimePicker: React.FunctionComponent<DatetimePickerProps> = ({
    className,
    openedDates,
    availableTimeSlots,
    date,
    minDate,
    maxDate,
    onDateChange,
    onTimeChange,
    onCalendarNavigation,
}: DatetimePickerProps) => {
    const { language, translations } = useLanguage();
    const t = translations.dateSelectorComponent;

    // Refs

    const calendarColRef = useRef<HTMLDivElement>(null);

    // States

    const [_selectedDate, setSelectedDate] = useState<Date | undefined>(date);

    // Effects

    useEffect(() => {
        if (onDateChange && _selectedDate) onDateChange(_selectedDate);
    }, [_selectedDate]);

    useEffect(() => {
        if (date !== _selectedDate) {
            setSelectedDate(date);
        }
    }, [date]);

    // Rendering

    const dateString = _selectedDate
        ? DateTime.fromJSDate(_selectedDate)
              .setLocale(language)
              .toLocaleString(DateTime.DATE_MED)
        : "";

    const maxTimePickerHeight =
        calendarColRef !== null && calendarColRef.current
            ? calendarColRef.current.clientHeight
            : DEFAULT_PICKER_MAX_HEIGHT;

    return (
        <div className={`${styles.holder} ${className ?? ""}`}>
            <div className={`${styles.calendarCol}`} ref={calendarColRef}>
                <Field
                    placeholder={t.choseDateLabel}
                    value={dateString}
                    readOnly={true}
                />
                <div className={styles.calendarHolder}>
                    <CalendarPicker
                        minDate={minDate}
                        maxDate={maxDate}
                        onDateChange={setSelectedDate}
                        openedDates={openedDates}
                        onCalendarNavigation={onCalendarNavigation}
                    />
                </div>
            </div>
            <div className={`${styles.timeCol}`}>
                <TimePicker
                    maxHeight={maxTimePickerHeight}
                    onSelectTime={onTimeChange}
                    labelText={t.choseHourLabel}
                    availableTimeSlots={availableTimeSlots}
                />
            </div>
        </div>
    );
};
export default DatetimePicker;
