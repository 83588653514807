import React, { useEffect, useState } from "react";

import styles from "./checkbox.module.css";

interface CheckboxProps {
    children?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    checked?: boolean;
    preventUncheck?: boolean;
    onChange?: (checked: boolean) => void;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    children,
    className,
    disabled = false,
    checked = false,
    preventUncheck = false,
    onChange,
}: CheckboxProps) => {
    // States

    const [_checked, _setChecked] = useState(checked);

    // Effects

    useEffect(() => {
        if (onChange && _checked !== checked) onChange(_checked);
    }, [_checked]);

    useEffect(() => {
        _setChecked(checked);
    }, [checked]);

    // Handlers

    const onClick = () => {
        if (!!preventUncheck && _checked) {
            return;
        }

        _setChecked(!_checked);
    };

    // Rendering

    let rootClass = styles.checkbox;
    if (className) rootClass += ` ${className}`;

    return (
        <div className={rootClass}>
            <button disabled={disabled} onClick={onClick}>
                {_checked && <div />}
            </button>
            <label>{children}</label>
        </div>
    );
};

export default Checkbox;
