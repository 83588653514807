import { useContext } from "react";
import AppContext from "../context/app";
import User from "../models/user";

type AuthUserHook = [User | null, (user: User | null) => void];

const useAuthUser = (): AuthUserHook => {
    const { authUser, setAuthUser } = useContext(AppContext);
    return [authUser, setAuthUser];
};

export default useAuthUser;
